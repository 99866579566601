import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import {ReactComponent as Agreement} from '../../images/Navigation/Agreement.svg'
import { useTranslation } from "react-i18next";
import './invoice.scss'
import urlFromTemplate from '../../configs/url';
import { ENDPOINTS } from '../../configs/endpoints';
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MyModal = ({content}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t, i18n } = useTranslation();


  const handleOpenAgreement = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
    try {
      const response = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Credentials': 'true'
          },
          responseType: 'blob'
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      // console.log(file)
      window.open(fileURL);
  } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
  }
  }
  
  return (
    <div>
      <Button  onClick={handleOpen}>
         <Agreement
                   style={{
                    width:'25px',
                    height:'25px'
        }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            {t('invoice')}
          </Typography>
          {content.length > 0 && content.map((row) => (
           <div style={{width:'90%', cursor:'pointer'}}>
           <div className='imagePdfInvoice'
                  onClick={() => handleOpenAgreement(row)}
           >
           </div>
       </div>
          ))}

          <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="secondary">
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MyModal;
