import React, { useEffect, useState } from "react";
import "../../Style/Project/Project.scss";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import {
  GaugeContainer,
  GaugeReferenceArc,
  GaugeValueArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { fetchImageById } from "../../Fetches/fetchFileById";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";

function ProjectDetails() {
  const [primaryImage, setPrimaryImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [document, setDocument] = useState(null);
  const [project, setProject] = useState();
  const { t, i18n } = useTranslation();

  useSelector((state) => state.OpenNavBar.dataNav);
  const [gaugeWidth, setGaugeWidth] = useState(120);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.project) {
      setProject(location.state.project);
    }
  }, [location]);

  useEffect(() => {
    const retrieveFiles = async () => {
      // Retrieve primary image
      setPrimaryImage(
        await fetchImageById(project.projectFiles.primaryPhotoId)
      );

      // Retrieve additional images
      const additionalImagePromises =
        project.projectFiles.additionalPhotoIdList.map(async (id) => {
          return await fetchImageById(id);
        });
      const additionalImageUrls = await Promise.all(additionalImagePromises);
      setAdditionalImages(additionalImageUrls.filter((url) => url !== null)); // Filter out null values

      // Retrieve document
      setDocument(await fetchImageById(project.projectFiles.documentId));
    };

    if (project) {
      retrieveFiles();
    }
  }, [project]);

  const handleChangeProject = () => {
    navigate("../change-project", {
      state: {
        project: project,
      },
    });
  };

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      // No value to display
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="red" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="red"
          strokeWidth={3}
        />
      </g>
    );
  }

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCloseProject = async (id) => {
    const payload = {

    }
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT) + `/close-project/${id}`;
    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);
      
    }
  };

  return (
    <>
      {!project && <div>Project is empty</div>}
      {project && (
        <div className="mainPageBody project-details">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "95%",
              alignItems: "center",
            }}
          >
            <div
              className="projectNameDescription"
              style={{ marginTop: "15px" }}
            >
              {project.title}
            </div>
            <div
              className="moreDetails activeElement"
              style={{ margin: "5px 10px 0 0" }}
              onClick={() => handleChangeProject()}
            >
              <Link className="moreDetails">
                {t("MainPageProjectButtonChange")}
              </Link>
            </div>
          </div>
          <div className="projectWrapper">
            <div className="projectContainer" style={{ padding: "0px" }}>
              <div className="projectPreviewWrapper" style={{ padding: "0px" }}>
                <div className="projectContainer" style={{ padding: "0px" }}>
                  {primaryImage ? (
                    <div
                      className="projectImg"
                      style={{ backgroundImage: `url(${primaryImage})` }}
                    ></div>
                  ) : (
                    <div className="projectImg placeholderImage"></div>
                  )}
                  <div className="projectInfoWrapper">
                    <div
                      className="projectInfoFirst"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="projectInfo">
                        <div className="imgInfo"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">20%</div>
                          <div className="infoText">
                            {t("MainPageProjectProfitPerMonth")}
                          </div>
                        </div>
                      </div>
                      <div className="projectInfo">
                        <div className="imgInfo"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">$ {project.minPrice}</div>
                          <div className="infoText" id="infoTextGreen">
                            {t("MainPageProjectMinimumTransactionAmount")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="projectInfoSecond"
                      style={{ marginTop: "40px" }}
                    >
                      <div className="projectInfo">
                        <div className="imgInfo" id="calendarProject"></div>
                        <div className="infoTextWrapper">
                          <div className="infoText">
                            {t(
                              "MainPageProjectDurationOfPlacementInTheProgramStart"
                            )}
                            : {project.beginDate}
                          </div>
                          <div className="infoText">
                            {t(
                              "MainPageProjectDurationOfPlacementInTheProgramEnd"
                            )}
                            : {project.endDate}
                          </div>
                        </div>
                      </div>
                      <div className="projectInfo">
                        <div className="imgInfo"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">
                            €{project.repeatPrice}
                          </div>
                          <div className="infoText" id="infoTextGreen">
                            {t("MainPageProjectMinRepeatTransactionAmount")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="projectPullWrapper">
                    <div className="projectPull">
                      <GaugeContainer
                        width={gaugeWidth}
                        height={150}
                        startAngle={-110}
                        endAngle={110}
                        value={(project.income / project.poolSum) * 100}
                      >
                        <GaugeReferenceArc />
                        <GaugeValueArc />
                        <GaugePointer />
                      </GaugeContainer>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "200px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "13px" }}>
                          €{project.income}
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          €{project.poolSum}
                        </div>
                      </div>
                      <div style={{ margin: "5px auto" }}>
                        {t("MainPageProjectPoolFilling")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                onClick={() => handleCloseProject(project.id)}
                style={{
                  background: "red",
                  color: "white",
                  borderRadius: "10px",
                  marginLeft: "60px",
                }}
              >
                {t('MainPageProjectCloseProject')}
              </Button>
            </div>
            <div className="infoMoney projectAdditionalInfo">
              {project.description}
            </div>
            <YMaps>
              <div className="project-map">
                <Map
                  defaultState={{
                    center: [project.lat, project.lng],
                    zoom: 10,
                  }}
                  style={{ width: "100%", height: "100%" }}
                >
                  <Placemark geometry={[project.lat, project.lng]} />
                </Map>
              </div>
            </YMaps>
            {additionalImages && additionalImages.length > 0 && (
              <div className="nameWrapper" style={{ marginBottom: "10px" }}>
                <span className="nameText">{t('MainPageProjectMoreDetailsImg')}</span>
              </div>
            )}
            <div className="additionalImageWrapper">
              {additionalImages.map((imageUrl, index) => (
                <div
                  key={index}
                  className="additionalImage"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectDetails;
