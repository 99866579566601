// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: {
        //Общие
        'close':'Close',
        'send':'Send',
        'reason':'Please indicate the reason for refusal',
        // Навигация
        "lorem": "Home",
        "cabinetClient": "Projects",
        "profile": "Roles",
        "bills": 'Activation',
        "partners":'Partners',
        "structure": 'Structure',
        "events": 'Events',
        "transfers":'Transfers',
        "verification":'Verification',
        "agreement":'Agreements',
        'invoice': 'Invoices',
        "exit": 'Exit',
                   // Кабинет Партнера Навигация
                   'CabinetPartnerNavCabinetPartner': `Consultant office`,
                   'CabinetPartnerNavStructureMain': 'Structure',
                   'CabinetPartnerNavStructureTable': 'Table Structure',
                   'CabinetPartnerNavStructureGraph': 'Graphic Structure',
        //Проекты
        'CreateProjectTitleSteps':'Create a new project',
        'CreateProjectTitleStepsFirst': '(Step 1 of 2)',
        'CreateProjectTitleStepsSecond': '(Step 2 of 2)',
        'MainPageProjectDurationOfPlacementInTheProgramStart' : 'Start',
        'MainPageProjectDurationOfPlacementInTheProgramEnd' : 'End',
        'MainPageProjectTitle': 'Projects',
        'MainPageProjectButtonAdd': 'Add project',
        'MainPageProjectButtonAd': 'Add',
        'MainPageProjectButtonA': 'Add User',
        'MainPageProjectButtonChange': 'Change',
        'MainPageProjectMoreDetails': 'More details',
        'MainPageProjectProfitPerMonth': 'Profit per month',
        'MainPageProjectDurationOfPlacementInTheProgram' : 'Duration of placement in the program',
        'MainPageProjectMinimumTransactionAmount' : 'Minimum transaction amount',
        'MainPageProjectMinRepeatTransactionAmount': 'Min. repeat transaction amount',
        'MainPageProjectPoolFilling': 'Total pool',
        'MainPageProjectCloseProject':'Complete the project',
        'MainPageProjectMoreDetailsImg':'Project images',
        //Создание проекта
           
        'ChangeProjectTitle':'Make changes to the project',
        'CreateProjectTitle': 'Create a new project (Step 1 of 2)',
        'CreateProjectInfo': 'General Information',
        'CreateProjectLocation': 'Geolocation',
        'CreateProjectNameProject': 'Project Name',
        'CreateProjectDescriptionProject': 'Project Description',
        'CreateProjectMinSum': 'Min. initial investment amount',
        'CreateProjectMinSumSecond': 'Min. reinvestment amount',
        'CreateProjectWaysMain': 'Investment methods for the project',
        'CreateProjectWayFirst': 'From the main account only',
        'CreateProjectWaySecond': 'From the main or bonus account',
        'CreateProjectWayThird': 'From any account',
        'CreateProjectDate': 'Completion date',
        'CreateProjectDateStart': 'Start date',
        'CreateProjectSumPull': 'Pool amount',
        'CreateProjectMainButton': 'Create project',
        'CreateProjectMainButtonUpdate':'Update project',
            // Шаг 2
            'CreateProjectSecondStepTitle': 'Second Step',
            'CreateProjectSecondStepImg': 'Images and Documents',
            'CreateProjectSecondStepMainImg': 'Main Project Image',
            'CreateProjectSecondStepSecondImg': 'Additional Project Images',
            'CreateProjectSecondStepDocProject': 'Project Document',
            'CreateProjectSecondStepButtonSelect': 'Select',
            'CreateProjectSecondStepButtonInstall': 'Install',
            'CreateProjectSecondStepButtonEnd': 'Finish Adding Files',
            'CreateProjectSecondStepButtonAdd': 'Add',

        // Footer 
        'FooterLegalInformation':'Legal information',
        'FooterPrivacyPolicy':'Privacy Policy',
        'FooterTermsOfUse':'Terms of use',
        'FooterSupport':'Support',
        'FooterSocial':'Social network',
        'FooterSocialTelegramNews':'News channel for Clients',
        'FooterSocialTelegramNav':'Navigation channel',
        'FooterSocialYouTube':'Youtube channel',
        'FooterSocialInstagram':'Instagram channel',
        // Роли
        'RoleStatus':'Statuses',
        'RoleSystemGift':'Reward System',
        'RoleSystemEducation':'Education system',
        // Поиск
        'SearchTitle':'Activation',
        //Кабинет партнера Структура личных приглашений
        'CabinetPartnerStructureTitle': 'Personal table structure',
        //Кабинет партнера кол-во партнеров
          'CabinetPartnerStructureBarNew': 'New partners',
          'CabinetPartnerStructureBarPersonal': 'Personally invited',
          'CabinetPartnerStructureBarAll': 'Total partners',
          'CabinetPartnerStructureBarActive': 'Active in 1st line',
          'CabinetPartnerStructureBarActiveSecond': 'Active in structure',
        //Кабинет партнера ЛИНЕЙНАЯ Структура
        'CabinetPartnerLinearStructureTitle':'Linear structure',
          //Настройки сайта
          'SiteSettingsModalTitle': 'Site Settings',
          'SiteSettingsModalLableCountry': 'Timezone',
          'SiteSettingsModalLabelLanguage': 'Language',
          'SiteSettingsModalLabelCurrancy': 'Currency',
          'SiteSettingsModalButtonClose': 'Cancel',
          'SiteSettingsModalButtonSave': 'Save',
            //Активация
            'CabinetPartnerModalWindowThirdTabRegistrationFeeName':'Name',
            'CabinetPartnerModalWindowThirdTabRegistrationFeeStatus':'Status',
            'CabinetPartnerModalWindowThirdTabRegistrationFee':'Consultant Registration Fee Paid',
            'CabinetPartnerModalWindowThirdTabRegistrationFeeButton':'Pay',
            'CabinetPartnerModalWindowThirdTabSign':'Consultant Agreement Signed',
            'CabinetPartnerModalWindowThirdTabSignButton':'Sign',
            'CabinetPartnerModalWindowThirdTabSalesConversations':'Knowledge of "Sales Conversations"',
            'CabinetPartnerModalWindowThirdTabFunctionality':'Knowledge of Personal Account Functionality and Administration',
            'CabinetPartnerModalWindowThirdTabAgreementClient':'Knowledge of Client Agreement',
            'CabinetPartnerModalWindowThirdTabAgreementConsultant':'Knowledge of Consultant Agreement',
            'CabinetPartnerModalWindowThirdTabDirector':'Training Center Director',
            'CabinetPartnerModalWindowThirdTabManager':'Training Center Manager',
            'CabinetPartnerModalWindowThirdTabNext':'Next',
        //События
        'EventModalWindowTitle':'Create an event',
        'EventModalWindowName':'Event',
        'EventModalWindowTextFieldName':'Name',
        'EventModalWindowTextFieldDate': 'Date',
        'EventModalWindowTextFieldDesc':'Description',
                //Second Tab
                'CabinetPartnerModalWindowSecondTabTitle':'Questionnaire',
                'CabinetPartnerModalWindowSecondTabName':'Full Name (Latin alphabet)',
                'CabinetPartnerModalWindowSecondTabBirthday':'Date of Birth',
                'CabinetPartnerModalWindowSecondTabLocation':'Place of Residence',
                'CabinetPartnerModalWindowSecondTabPhone':'Phone Number',
                'CabinetPartnerModalWindowSecondTabFamily':'Marital Status',
                'CabinetPartnerModalWindowSecondTabEducation':'Education',
                'CabinetPartnerModalWindowSecondTabWork':'Current Job',
                'CabinetPartnerModalWindowSecondTabHobby':'Hobbies',
                'CabinetPartnerModalWindowSecondTabComputerTitle':'Computer Usage:',
                'CabinetPartnerModalWindowSecondTabEmail':'Personal Email',
                'CabinetPartnerModalWindowSecondTabVk':'VK',
                'CabinetPartnerModalWindowSecondTabSubmit':'Submit',
                       // Переводы
          'TransactionsButtonTrue':'Accept',
          'TransactionsButtonFalse':'Reject',
                 // Agreement
                 'AgreementConsultant':'Consultant',
                 'AgreementProject':'Project',
                 'AgreementWithdraw':'Exit',
                 'MainPageTransactionsDoc': 'Agreement file',
                 'AgreementTime':'Last update time',
                 'AgreementUserName':'Login',
                 'AgreementType':'Contract type',
          // Кошельки
          'buttonConnectWallet': 'Manage Wallets',
          'WalletNumber': 'Wallet number',
          'OldWalletNumber': 'Old wallet number',
          'NewWalletNumber': 'New wallet number',
          'Update': 'Update',
          'Delete': 'Delete',
          'Currency': 'Currency',
          'Reason': 'Reason',
          'WalletManagement': 'Wallet management',
          'SystemWallets': 'System wallets',
          // Дни недели
          'DayOfWeek': 'Day of week',
          'MONDAY': 'Monday',
          'TUESDAY': 'Tuesday',
          'WEDNESDAY': 'Wednesday',
          'THURSDAY': 'Thursday',
          'FRIDAY': 'Friday',
          'SATURDAY': 'Saturday',
          'SUNDAY': 'Sunday'

      }
    },
    Русский: {
      translation: {
        //Общие
        'close':'Закрыть',
        'send':'Отправить',
        'reason':'Укажите причину отказа',

        // Навигация
        "lorem": "Главная",
        "cabinetClient": "Проекты",
        "profile": "Роли",
        "bills": 'Активация',
        "structure": 'Структура',
        "partners":'Партнеры',
        "events":'События',
        "transfers":'Переводы',
        "verification":'Верификация',
        "agreement":'Договоры',
        'invoice': 'Инвойсы',
        "exit": 'Выход',
                    // Кабинет Партнера Навигация
                    'CabinetPartnerNavCabinetPartner': 'Кабинет консультанта',
                    'CabinetPartnerNavStructureMain': 'Структура',
                    'CabinetPartnerNavStructureTable': 'Табличная Структура',
                    'CabinetPartnerNavStructureGraph': 'Графическая Структура',
        // Проекты
        'CreateProjectTitleSteps':'Создайте новый проект',
        'CreateProjectTitleStepsFirst':'(Шаг 1 из 2)',
        'CreateProjectTitleStepsSecond':'(Шаг 2 из 2)',

        'MainPageProjectTitle': 'Проекты',
        'MainPageProjectButtonAdd': 'Добавить проект',  
        'MainPageProjectButtonAd': 'Добавить',
        'MainPageProjectButtonChange': 'Изменить',   
        'MainPageProjectMoreDetails': 'Подробнее',
        'MainPageProjectProfitPerMonth': 'Доходность в месяц',
        'MainPageProjectDurationOfPlacementInTheProgram' : 'Срок размещения в программе',
        'MainPageProjectMinimumTransactionAmount' : 'Минимальная сумма сделки',
        'MainPageProjectMinRepeatTransactionAmount': 'Мин. сумма повторной сделки',
        'MainPageProjectPoolFilling': 'Наполнение пула',
        'MainPageProjectCloseProject':'Завершить проект',
        //Проект
        'MainPageProjectMoreDetailsImg':'Изображения проекта',
        'MainPageProjectDurationOfPlacementInTheProgramStart':'Начало',
        'MainPageProjectDurationOfPlacementInTheProgramEnd':'Конец',
        // Создание проекта
            // шаг 1
          'ChangeProjectTitle':'Внесите изменения в проект',
         'CreateProjectTitle': 'Создайте новый проект (Шаг 1 из 2)',
         'CreateProjectInfo': 'Общая информация',
         'CreateProjectLocation': 'Геолокация',
         'CreateProjectNameProject': 'Название проекта',
         'CreateProjectDescriptionProject': 'Описание проекта',
         'CreateProjectMinSum': 'Мин. сумма первоначального вложения',
         'CreateProjectMinSumSecond': 'Мин. сумма повторного вложения',
         'CreateProjectWaysMain': 'Способы вложения в проект',
         'CreateProjectWayFirst': 'Только с основного счета',
         'CreateProjectWaySecond': 'С основного или основного накопительного счетов',
         'CreateProjectWayThird': 'С любого счета',
         'CreateProjectDate': 'Дата заверешения',
         'CreateProjectDateStart': 'Дата начала',
         'CreateProjectSumPull': 'Сумма пула',
         'CreateProjectMainButton': 'Создать проект',
         'CreateProjectMainButtonUpdate': 'Обновить проект',
            // шаг 2
          'CreateProjectSecondStepTitle': 'Файл был успешно добавлен',
          'CreateProjectSecondStepSuccess':'',
          'CreateProjectSecondStepImg': 'Изображения и документы',
          'CreateProjectSecondStepMainImg': 'Основное изображение проекта',
          'CreateProjectSecondStepSecondImg': 'Дополнительные изображения проекта',
          'CreateProjectSecondStepDocProject': 'Документ проекта',
          'CreateProjectSecondStepButtonSelect': 'Выбрать',
          'CreateProjectSecondStepButtonInstall': 'Установить',
          'CreateProjectSecondStepButtonEnd': 'Завершить добавление файлов',
          'CreateProjectSecondStepButtonAdd': 'Добавить',

        // Footer    
        'FooterLegalInformation':'Юридическая информация',
        'FooterPrivacyPolicy':'Политика конфиденциальности',
        'FooterTermsOfUse':'Пользовательское соглашение',
        'FooterSupport':'Тех.поддержка',
        'FooterSocial':'Соц.сети',
        'FooterSocialTelegramNews':' Новостной канал для Клиентов',
        'FooterSocialTelegramNav':'Навигационный канал',
        'FooterSocialYouTube':'Канал Youtube',
        'FooterSocialInstagram':'Канал Insagram',
        // Роли
        'RoleTitle':'Роли',
        'RoleStatus':'Статусы',
        'RoleSystemGift':'Система Поощрений',
        'RoleSystemEducation':'Система Обучения',
        //Поиск
        'SearchTitle':'Активация',
        //Кабинет партнера Структура личных приглашений
        'CabinetPartnerStructureTitle':'Структура личных приглашений',      
        //Кабинет партнера Структура кол-во партнеров
        'CabinetPartnerStructureBarNew':'Новых партнёров',      
        'CabinetPartnerStructureBarPersonal':'Лично приглашённых',      
        'CabinetPartnerStructureBarAll':'Всего партнеров',      
        'CabinetPartnerStructureBarActive':'Активных в 1-й линии',      
        'CabinetPartnerStructureBarActiveSecond':'Активных в структуре',  
         //Кабинет партнера ЛИНЕЙНАЯ Структура
         'CabinetPartnerLinearStructureTitle':'Линейная структура',
              //Настройки сайта
              'SiteSettingsModalTitle':'Настройки сайта',
              'SiteSettingsModalLableCountry':'Часовой пояс',
              'SiteSettingsModalLabelLanguage':'Язык',
              'SiteSettingsModalLabelCurrancy':'Валюта',
              'SiteSettingsModalButtonClose':'Отмена',
              'SiteSettingsModalButtonSave':'Сохранить',

              //Активация
              'CabinetPartnerModalWindowThirdTabRegistrationFeeName':'Название',
              'CabinetPartnerModalWindowThirdTabRegistrationFeeStatus':'Статус',
              'CabinetPartnerModalWindowThirdTabRegistrationFee':'Оплачен активационный сбор консультанта',
              'CabinetPartnerModalWindowThirdTabRegistrationFeeButton':'Оплатить',
              'CabinetPartnerModalWindowThirdTabSign':'Подписан договор консультанта',
              'CabinetPartnerModalWindowThirdTabSignButton':'Подписать',
              'CabinetPartnerModalWindowThirdTabSalesConversations':'Знание «Беседы о продаже»',
              'CabinetPartnerModalWindowThirdTabFunctionality':'Знание функционала личного кабинета и администрирование ',
              'CabinetPartnerModalWindowThirdTabAgreementClient':'Знание договора клиента',
              'CabinetPartnerModalWindowThirdTabAgreementConsultant':'Знание договора консультанта',
              'CabinetPartnerModalWindowThirdTabDirector':'Директор тренингового центра',
              'CabinetPartnerModalWindowThirdTabManager':'Руководитель',
              'CabinetPartnerModalWindowThirdTabNext':'Дальше',

        //События
        'EventModalWindowTitle':'Создание события',
        'EventModalWindowName':'Событие',
        'EventModalWindowTextFieldName':'Название',
        'EventModalWindowTextFieldDate': 'Дата',
        'EventModalWindowTextFieldDesc':'Описание',
                 //Second Tab
                 'CabinetPartnerModalWindowSecondTabTitle':'Анкета',
                 'CabinetPartnerModalWindowSecondTabName':'Ф.И. (Латиницей)',
                 'CabinetPartnerModalWindowSecondTabBirthday':'Дата рождения',
                 'CabinetPartnerModalWindowSecondTabLocation':'Место проживания',
                 'CabinetPartnerModalWindowSecondTabPhone':'Номер телефона',
                 'CabinetPartnerModalWindowSecondTabFamily':'Семейное положение',
                 'CabinetPartnerModalWindowSecondTabEducation':'Образование',
                 'CabinetPartnerModalWindowSecondTabWork':'Текущее место работы',
                 'CabinetPartnerModalWindowSecondTabHobby':'Хобби',
                 'CabinetPartnerModalWindowSecondTabComputerTitle':'Пользование Персональным Компьютером:',
                 'CabinetPartnerModalWindowSecondTabEmail':'Персональную электронную почту',
                 'CabinetPartnerModalWindowSecondTabVk':'ВКонтакте',
                 'CabinetPartnerModalWindowSecondTabSubmit':'Дальше',
        // Переводы
          'TransactionsButtonTrue':'Принять',
          'TransactionsButtonFalse':'Отклонить',
        // Agreement
          'AgreementConsultant':'Консультант',
          'AgreementProject':'Проект',
          'AgreementWithdraw':'Вывод',
          'MainPageTransactionsDoc': 'Файл договора',
          'AgreementTime':'Время последнего обновления',
          'AgreementUserName':'Пользователь',
          'AgreementType':'Тип договора',
          // Кошельки
          'buttonConnectWallet': 'Управление Кошельками',
          'WalletNumber': 'Номер кошелька',
          'OldWalletNumber': 'Старый номер кошелька',
          'NewWalletNumber': 'Новый номер кошелька',
          'Update': 'Обновление',
          'Delete': 'Удаление',
          'Currency': 'Валюта',
          'Reason': 'Причина',
          'WalletManagement': 'Управление кошельками',
          'SystemWallets': 'Системные кошельки',

          // Дни недели
          'DayOfWeek': 'День недели',
          'MONDAY': 'Понедельник',
          'TUESDAY': 'Вторник',
          'WEDNESDAY': 'Среда',
          'THURSDAY': 'Четверг',
          'FRIDAY': 'Пятница',
          'SATURDAY': 'Суббота',
          'SUNDAY': 'Воскресенье'
      }
    }
  },
  lng: "English",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
