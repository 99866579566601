import React from "react";
import Navigation from "../Navigation/Navigation";
import {Box} from "@mui/material";
import {useMediaQuery, useTheme} from "@mui/material";
import MainPageContainer from "./MainPageContainer";

function MainPage() {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row'
    }

    return (
        <>
            <Box style={style}>
                <Navigation isSmallScreen={isSmallScreen}/>
                <MainPageContainer theme={theme} isSmallScreen={isSmallScreen}/>
            </Box>
        </>
    )
}

export default MainPage