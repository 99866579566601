import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dataNav: true
}

const openNavBarSlice = createSlice({
    name: 'openNavBar',
    initialState,
    reducers:{
        setDataNavBar: (state, action) =>{
            state.dataNav = action.payload
        }
    }
})

export const {setDataNavBar} = openNavBarSlice.actions
export default openNavBarSlice.reducer