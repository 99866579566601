import React from "react";
import TableRole from "./Table";
import TableRoleTitle from "./RoleTitle";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RewardSystem from "./RewardSystem";
import TrainingSystem from "./TrainingSystem";
import { useTranslation } from 'react-i18next';

function RoleContainer() {
  const [value, setValue] = React.useState("1");
  const { t, i18n } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TableRoleTitle />
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('RoleStatus')} value="1" />
              <Tab label={t('RoleSystemGift')} value="2" />
              <Tab label={t('RoleSystemEducation')} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {" "}
            <TableRole />
          </TabPanel>
          <TabPanel value="2"> <RewardSystem/> </TabPanel>
          <TabPanel value="3"> <TrainingSystem/> </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default RoleContainer;
