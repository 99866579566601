import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, Dialog, DialogContent, DialogTitle,} from "@mui/material";
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import axios from "axios";

function Transfer() {
    const {t, i18n} = useTranslation();
    const [rows, setRows] = useState([])
    const [openReject, setOpenReject] = useState(false)
    const [valueReject, setValueReject] = useState('')
    const [verificationId, setVerificationId] = useState()

    const handleGetPending = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG) + `/pending`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setRows(response.data.content)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetPending()
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };


    const handleUserWithdtaw = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/withdraw`
        console.log(id)
        const payload = {
            accountLogId: id
        }
        try {
            const response = await axios.post(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
        } catch (error) {
            console.error(error)
        } finally {
            await handleGetPending();
        }
    }

    const handleUserDecline = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/decline`
        const payload = {
            accountLogId: id,
            reason: valueReject,
        }
        try {
            const response = await axios.post(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
        } catch (error) {
            console.error(error)
        } finally {
            setValueReject('');
            await handleGetPending();
            handleCloseReject()
        }
    }

    const handleClickOpenReject = (id) => {
        setOpenReject(true)
        setVerificationId(id)
    }

    const handleCloseReject = () => {
        setOpenReject(false)
    }

    const handleChangeReject = (event) => {
        const newValue = event.target.value
        setValueReject(newValue)
    }

    return (
        <>
            <div className="wrapperEvents">
                <div className="nameWrapper">
                    <span className="nameText">{t("transfers")}</span>
                </div>

            </div>
            <div style={{maxWidth: '900px', display: 'flex', justifyContent: 'center', margin: '10px auto'}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell align="right">Login</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">Currency</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="right">{row.login}</TableCell>
                                    <TableCell align="right">{formatDate(row.dateTime)}</TableCell>
                                    <TableCell align="right">{row.currency}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                    <TableCell align="right"><Button
                                        onClick={() => handleClickOpenReject(row.id)}
                                        variant="contained"
                                        color="error"> {t('TransactionsButtonFalse')} </Button></TableCell>
                                    <TableCell align="right"><Button onClick={() => handleUserWithdtaw(row.id)}
                                                                     variant="contained"
                                                                     color="success"> {t('TransactionsButtonTrue')} </Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog open={openReject} onClose={handleCloseReject} maxWidth="lg">
                <DialogTitle>
                    Укажите причину отказа
                </DialogTitle>
                <DialogContent>
                    <textarea
                        value={valueReject}
                        onChange={(e) => handleChangeReject(e)}
                        style={{width: '500px', height: '100px'}}>

                    </textarea>
                </DialogContent>
                <Button onClick={() => handleUserDecline(verificationId)}>
                    Отправить
                </Button>
            </Dialog>
        </>
    )
}

export default Transfer