import React, {useState} from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import SiteSettingsModal from "../Modal/SiteSettingsModal";
import { useTranslation } from 'react-i18next';


function NavigationAdaptive({isOpen, onClose, isClose}) {
    const [activeItem, setActiveItem] = useState('Cabinet');
    const [isClientCabinet, setIsClientCabinet] = useState(() => {
        const storedValue = localStorage.getItem('isClientCabinet');
        return storedValue ? JSON.parse(storedValue) : true;
    });
    const [siteSettings, setSiteSettings] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t, i18n } = useTranslation();

    setTimeout(() => {
        setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
    }, 200);

    const handleModalOpen = () => {
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden'
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        document.body.style.overflow = 'auto'
    };

    const navigate = useNavigate()

    const handleExit = () => {
        navigate('/')
    }

    const handleLanding = () => {
        navigate('/')
    }
    const handleProfile = () => {
        navigate('/mainpage/role')
        onClose()

    }

    const handleCabinet = () => {
        navigate('/mainpage')
        onClose()

    }

    const handleBills = () => {
        navigate('/mainpage')
        onClose()

    }


    const handleItemClick = (item) => {
        setActiveItem(item);
        switch (item) {
            case 'lorem':
                handleLanding();
                break;
            case 'Cabinet':
                handleCabinet();
                break;
            case 'profile':
                handleProfile();
                break;
            case 'bills':
                handleBills();
                break;
            case 'exit':
                handleExit();
                break;
            default:
                break;
        }
    };


    const handleCabinetChange = () => {
        setIsClientCabinet(prevIsClientCabinet => !prevIsClientCabinet);
    };

    return (
        <>
            {isOpen && <Drawer
                open={isOpen}
                sx={{
                    //   width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        // width: drawerWidth,
                        boxSizing: 'border-box',
                        position: 'static',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row'
                    },
                }}
                variant="temporary"
                anchor="left"
            >
                <List style={{width: '100%'}}>
                    <ListItem>
                        <Button style={{color: activeItem === 'lorem' ? 'red' : 'black'}}
                                onClick={() => handleItemClick('lorem')}> {t('lorem')} </Button>
                    </ListItem>
                    <ListItem>
                        <Button style={{color: activeItem === 'Cabinet' ? 'red' : 'black'}}
                                onClick={() => handleItemClick('Cabinet')}> {t('cabinetClient')} </Button>
                    </ListItem>
                    <ListItem>
                        <Button onClick={() => handleItemClick('profile')}
                                style={{color: activeItem === 'profile' ? 'red' : 'black'}}>Lorem</Button>
                    </ListItem>
                    <ListItem>
                        <Button style={{color: activeItem === 'bills' ? 'red' : 'black'}}
                                onClick={() => handleItemClick('bills')}>Ipsum</Button>
                    </ListItem>
                    <ListItem>
                        <Button onClick={() => handleItemClick('exit')}
                                style={{color: activeItem === 'exit' ? 'red' : 'black'}}> {t('exit')} </Button>
                    </ListItem>
                    <ListItem>
                        <div className="containerHeaderButton">
                            <button
                                className="buttonSettingsWrapper"
                                onClick={handleModalOpen}
                            >
                                <div className="flagWrapper">
                                    <FlagIcon/>
                                </div>
                                <div className="languageValueWrapper">
                                    {siteSettings &&
                                        siteSettings.country !== undefined &&
                                        siteSettings.language !== undefined &&
                                        siteSettings.currency !== undefined && (
                                            <span>
                                                    {siteSettings.language}, {siteSettings.currency}
                                                </span>
                                        )}
                                    {(!siteSettings ||
                                        siteSettings.country === undefined ||
                                        siteSettings.language === undefined ||
                                        siteSettings.currency === undefined) && (
                                        <span>Русский, EUR</span>
                                    )}
                                </div>
                            </button>
                        </div>
                    </ListItem>
                </List>
                <ClearIcon onClick={onClose}/>
            </Drawer>}
            <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose}/>
        </>
    )


}

export default NavigationAdaptive