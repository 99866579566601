import React from "react";
import SearchUsers from "./SearchUser";
import SearchUserTitle from "./SearchUserTitle";


function SearchUserContainer () {
    return (
        <>
        <SearchUserTitle/>
        <SearchUsers/>
        </>
    )
}

export default SearchUserContainer