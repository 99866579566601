import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../Style/NameCabinet.scss";
import { TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import { Typography } from "@mui/material";
import { ReactComponent as CheckTrue } from "../../images/ModalWindowCabinetConsultant/checkTrue.svg";
import { ReactComponent as CheckFalse } from "../../images/ModalWindowCabinetConsultant/checkFalse.svg";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {  Checkbox, FormControlLabel,  FormGroup } from '@mui/material';
import "../../Style/ModalWindowRegistration/ModalWindowRegistration.scss";

function Partnership() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [userActivate, setUserActivate] = useState({});
  const [userActivatePut, setUserActivatePut] = useState({});
  const [formUser, setFormUser] = useState({})


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOpen = (id) => {
    handelGetUser(id);
    handelGetUserForm(id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Получение всех пользователей
  const handleGetUsers = async () => {
    const apiUrl =
      urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/admin`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  //Получение user по id
  const handelGetUser = async (id) => {
    const apiUrl =
      urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/admin/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setUserActivate(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(true);
    }
  };

    const handelGetUserForm = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_FORM) + `/${id}`
    try {
      const response = await axios.get(apiUrl,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
      setFormUser(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const [formData, setFormData] = useState({
    fullName: formUser.name,
    birthDate: formUser.dateOfBirth,
    residence: formUser.placeOfResidence,
    phoneNumber: formUser.phoneNumber,
    maritalStatus: formUser.familyStatus,
    education: formUser.education,
    currentJob: formUser.workPlace,
    hobbies: formUser.hobby,
    msWord: formUser.word,
    powerPoint: formUser.powerPoint,
    excel: formUser.excel,
    zoom: formUser.zoom,
    email: formUser.email,
    instagram: formUser.instagram,
    telegram: formUser.telegram,
    whatsapp: formUser.whatsapp,
    facebook: formUser.facebook,
    vkontakte: formUser.vk,
  });

  useEffect(() => {
    setFormData({
      fullName: formUser.name,
      birthDate: formUser.dateOfBirth,
      residence: formUser.placeOfResidence,
      phoneNumber: formUser.phoneNumber,
      maritalStatus: formUser.familyStatus,
      education: formUser.education,
      currentJob: formUser.workPlace,
      hobbies: formUser.hobby,
      pcUsage: true,
      msWord: formUser.word,
      powerPoint: formUser.powerPoint,
      excel: formUser.excel,
      zoom: formUser.zoom,
      email: formUser.email,
      instagram: formUser.instagram,
      telegram: formUser.telegram,
      whatsapp: formUser.whatsapp,
      facebook: formUser.facebook,
      vkontakte: formUser.vk,
    })
  },[formUser])

  // Активация пользователя 

  const handleActiveUser = (name) => {
    setUserActivate((prevState) => ({
        ...prevState,
        [name]: true,
      }));
      setUserActivatePut(userActivate)
  }

  useEffect(() => {
    if(userActivatePut.salesConversationsByAdmin === undefined){
        return
      }else {
        handleActiveUserPut();
      }
  },[userActivatePut])

  const handleActiveUserPut = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/admin`

    try{
        const response = await axios.put(apiUrl, userActivate ,{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
        })
    } catch (error) {
        console.error(error)
    }
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }
  return (
    <>
      <div className="wrapperPartners">
        <div className="nameWrapper">
          <span className="nameText">{t("SearchTitle")}</span>
        </div>
        <div className="wrapperBonusPartner" style={{display:'flex', justifyContent:'center'}}>
          <div className="usersWrapper" style={{ width: "90%" }}>
            <TableContainer
              component={Paper}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Table
                sx={{ minWidth: 650, width: "90%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="right">id</TableCell>
                    <TableCell align="right">login</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => handleOpen(item.id)}
                    >
                      <TableCell align="right">{item.id}</TableCell>
                      <TableCell align="right">{item.login}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "70%", overflow:'auto' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
        </Tabs>

      </Box>
      <CustomTabPanel value={value} index={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell align="left">Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userActivate ? (
                <>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t(
                          "CabinetPartnerModalWindowThirdTabSalesConversations"
                        )}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.salesConversationsByAdmin ? (
          <CheckTrue style={{ width: "26px", height: "28px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "28px", cursor:'pointer' }} onClick={() => handleActiveUser('salesConversationsByAdmin')} />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t("CabinetPartnerModalWindowThirdTabFunctionality")}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.accountFunctionalityByAdmin ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer' }} onClick={() => handleActiveUser('accountFunctionalityByAdmin')}  />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t("CabinetPartnerModalWindowThirdTabAgreementClient")}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.clientAgreementByAdmin ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer' }} onClick={() => handleActiveUser('clientAgreementByAdmin')} />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t(
                          "CabinetPartnerModalWindowThirdTabAgreementConsultant"
                        )}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {userActivate.consultantAgreementByAdmin ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer'}} onClick={() => handleActiveUser("consultantAgreementByAdmin")}  />
        )}
                  </TableCell>
                </TableRow>
                </>
              ) : null}
            </TableBody>
          </Table>
      </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
          <div className='secondTabWrapper'>
      <form className='secondTabForm'>
        <Typography variant="h4"> {t('CabinetPartnerModalWindowSecondTabTitle')} </Typography>
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabName')}
          name="fullName"
          value={formData.fullName}
          disabled={true}
          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabBirthday')}
          name="birthDate"
          value={formData.birthDate}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabLocation')}
          name="residence"
          value={formData.residence}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabPhone')}
          name="phoneNumber"
          value={formData.phoneNumber}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabFamily')}
          name="maritalStatus"
          value={formData.maritalStatus}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabEducation')}
          name="education"
          value={formData.education}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabWork')}
          name="currentJob"
          value={formData.currentJob}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabHobby')}
          name="hobbies"
          value={formData.hobbies}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormGroup>
          <Typography variant="h6"> {t('CabinetPartnerModalWindowSecondTabComputerTitle')} </Typography>
          <FormControlLabel
            control={<Checkbox checked={formData.msWord} onChange={handleChange} name="msWord" />}
            label="Microsoft Word / Pages"
          disabled={true}

          />
          <FormControlLabel
            control={<Checkbox checked={formData.powerPoint} onChange={handleChange} name="powerPoint" />}
            label="Power Point / Keynote"
          disabled={true}

          />
          <FormControlLabel
            control={<Checkbox checked={formData.excel} onChange={handleChange} name="excel" />}
            label="Excel / Numbers"
          disabled={true}

          />
          <FormControlLabel
            control={<Checkbox checked={formData.zoom} onChange={handleChange} name="zoom" />}
            label="Zoom"
          disabled={true}

          />
        </FormGroup>
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabEmail')}
          name="email"
          value={formData.email}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="Instagram"
          name="instagram"
          value={formData.instagram}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="Telegram"
          name="telegram"
          value={formData.telegram}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="WhatsApp"
          name="whatsapp"
          value={formData.whatsapp}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="FaceBook"
          name="facebook"
          value={formData.facebook}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabVk')}
          name="vkontakte"
          value={formData.vkontakte}
          disabled={true}

          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </div>
      </CustomTabPanel>
        </Box>
      </Modal>
    </>
  );
}

export default Partnership;
