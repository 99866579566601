import React, {useEffect, useRef, useState} from 'react';
import * as go from 'gojs';
import axios from 'axios';
import './stylesGraph.scss'
import urlFromTemplate from '../configs/url';
import {ENDPOINTS} from '../configs/endpoints';
import GraphicalStructureTitle from "./GraphicalStructureTitle";
import InviteCounts from "./InviteCounts";

function GraphicalRepresentation() {
    const nameProperty = "login";
    const genderProperty = "gender";
    const inviterId = "id";
    const statusProperty = "status";
    const countProperty = "count";
    const [familyData, setFamilyData] = useState([]);
    const [counts, setCounts] = useState([]);

    const graphicalStructure = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/admin-structure`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            });
            setCounts(response.data.counts);
            setFamilyData(response.data.structure);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        graphicalStructure();
    }, []);

    const theme = {
        colors: {
            femaleBadgeBackground: "#FFCBEA",
            maleBadgeBackground: "#A2DAFF",
            femaleBadgeText: "#7A005E",
            maleBadgeText: "#001C76",
            kingQueenBorder: "#FEBA00",
            princePrincessBorder: "#679DDA",
            civilianBorder: "#58ADA7",
            personText: "#383838",
            personNodeBackground: "#FFFFFF",
            selectionStroke: "#485670",
            counterBackground: "#485670",
            counterBorder: "#FFFFFF",
            counterText: "#FFFFFF",
            link: "#686E76",
        },
        fonts: {
            badgeFont: "bold 12px Poppins",
            birthDeathFont: "14px Poppins",
            nameFont: "500 18px Poppins",
            counterFont: "14px Poppins",
        },
    };

    const STROKE_WIDTH = 3;
    const ADORNMENT_STROKE_WIDTH = STROKE_WIDTH + 1;
    const CORNER_ROUNDNESS = 12;
    const IMAGE_TOP_MARGIN = 20;
    const MAIN_SHAPE_NAME = "mainShape";
    const IMAGE_DIAMETER = 40;

    const getStrokeForStatus = (status) => {
        switch (status) {
            case "king":
            case "queen":
                return theme.colors.kingQueenBorder;
            case "prince":
            case "princess":
                return theme.colors.princePrincessBorder;
            case "civilian":
            default:
                return theme.colors.civilianBorder;
        }
    };

    const statusStrokeBinding = () =>
        new go.Binding("stroke", statusProperty, (status) =>
            getStrokeForStatus(status)
        );

    const highlightStrokeBinding = () =>
        new go.Binding("stroke", "isHighlighted", (isHighlighted, obj) =>
            isHighlighted
                ? theme.colors.selectionStroke
                : getStrokeForStatus(obj.part.data.status)
        ).ofObject();

    const genderToText = (gender) => (gender === "M" ? "MALE" : "FEMALE");

    const genderToTextColor = (gender) =>
        gender === "M" ? theme.colors.maleBadgeText : theme.colors.femaleBadgeText;

    const genderToFillColor = (gender) =>
        gender === "M"
            ? theme.colors.maleBadgeBackground
            : theme.colors.femaleBadgeBackground;

    const personBadge = () =>
        new go.Panel("Auto", {
            alignmentFocus: go.Spot.TopRight,
            alignment: new go.Spot(1, 0, -25, STROKE_WIDTH - 0.5)
        })
            .add(new go.Shape(
                {
                    figure: "RoundedRectangle",
                    parameter1: CORNER_ROUNDNESS,
                    parameter2: 4 | 8, // round only the bottom
                    desiredSize: new go.Size(NaN, 22.5),
                    stroke: null,
                })
                .bind("fill", genderProperty, genderToFillColor)
            )
            .add(new go.TextBlock(
                {
                    font: theme.fonts.badgeFont,
                })
                .bind("stroke", genderProperty, genderToTextColor)
                .bind("text", genderProperty, genderToText)
            );

    const personBirthDeathTextBlock = () =>
        new go.TextBlock(
            {
                stroke: theme.colors.personText,
                font: theme.fonts.birthDeathFont,
                alignmentFocus: go.Spot.Top,
                alignment: new go.Spot(0.5, 1, 0, -35)
            })
            .bind("text", "", ({level, death}) => {
                if (!level) return "";
                return `level ${level}`;
            });

    const personCounter = () =>
        new go.Panel("Auto", {
            visible: false,
            alignmentFocus: go.Spot.Center,
            alignment: go.Spot.Bottom
        })
            .bind("visible", "", (obj) => obj.findLinksOutOf().count > 0)
            .add(new go.Shape("Circle", {
                desiredSize: new go.Size(29, 29),
                strokeWidth: STROKE_WIDTH,
                stroke: theme.colors.counterBorder,
                fill: theme.colors.counterBackground,
            }))
            .add(new go.TextBlock(
                {
                    alignment: new go.Spot(0.5, 0.5, 0, 1),
                    stroke: theme.colors.counterText,
                    font: theme.fonts.counterFont,
                    textAlign: "center",
                })
                .bind("text", "", (obj) => obj.findNodesOutOf().count)
            );


    const personImage = (imageUrl) =>
        new go.Panel("Spot", {
            alignmentFocus: go.Spot.Top,
            alignment: new go.Spot(0, 0, STROKE_WIDTH / 2, IMAGE_TOP_MARGIN),
        })
            .add(new go.Shape({
                    figure: "Circle",
                    fill: theme.colors.personNodeBackground,
                    strokeWidth: STROKE_WIDTH,
                    geometryString: "f M0 0 L100 0 L100 100 L0 100 z M5,50",
                    fill: 'white',
                    desiredSize: new go.Size(IMAGE_DIAMETER, IMAGE_DIAMETER),
                })
                    .bind(statusStrokeBinding())
                    .bind(highlightStrokeBinding())
            )
            .add(new go.Picture({
                    margin: new go.Margin(0, 0, IMAGE_TOP_MARGIN, 0),
                    desiredSize: new go.Size(IMAGE_DIAMETER, IMAGE_DIAMETER),
                    geometryString: "f M0 0 L100 0 L100 100 L0 100 z M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0 z",
                    source: "../../../images/Black.png",// Placeholder image
                })
                    .bind("source", "imageUrl")
            );

    const personMainShape = () =>
        new go.Shape(
            {
                figure: "RoundedRectangle",
                desiredSize: new go.Size(215, 110),
                fill: theme.colors.personNodeBackground,
                portId: "",
                parameter1: CORNER_ROUNDNESS,
                strokeWidth: STROKE_WIDTH
            })
            .bind(statusStrokeBinding())
            .bind(highlightStrokeBinding());

    const personNameTextBlock = () =>
        new go.TextBlock(
            {
                stroke: theme.colors.personText,
                font: theme.fonts.nameFont,
                desiredSize: new go.Size(160, 50),
                overflow: go.TextOverflow.Ellipsis,
                textAlign: "center",
                verticalAlignment: go.Spot.Center,
                toolTip: go.GraphObject.make("ToolTip")
                    .add(new go.TextBlock({margin: 4}).bind("text", nameProperty)),
                alignmentFocus: go.Spot.Top,
                alignment: new go.Spot(0.5, 0, 0, 25)
            })
            .bind("text", nameProperty);

    const createNodeTemplate = () =>
        new go.Node("Spot",
            {
                selectionAdorned: false,
                mouseEnter: onMouseEnterPart,
                mouseLeave: onMouseLeavePart,
                selectionChanged: onSelectionChange
            })
            .add(new go.Panel("Spot")
                .add(personMainShape())
                .add(personNameTextBlock())
                .add(personBirthDeathTextBlock())
            )
            .add(personImage())
            .add(personBadge())
            .add(personCounter())


    const createLinkTemplate = () =>
        new go.Link(
            {
                selectionAdorned: false,
                routing: go.Link.Orthogonal,
                layerName: "Background",
                mouseEnter: onMouseEnterPart,
                mouseLeave: onMouseLeavePart,
            })
            .add(new go.Shape(
                {
                    stroke: theme.colors.link,
                    strokeWidth: 1,
                })
                .bind("stroke", "isHighlighted", (isHighlighted) =>
                    isHighlighted ? theme.colors.selectionStroke : theme.colors.link
                )
                .bind("stroke", "isSelected", (selected) =>
                    selected ? theme.colors.selectionStroke : theme.colors.link
                )
                .bind("strokeWidth", "isSelected", (selected) => selected ? 2 : 1)
            );

    const onMouseEnterPart = (e, part) => part.isHighlighted = true;
    const onMouseLeavePart = (e, part) => {
        if (!part.isSelected) part.isHighlighted = false;
    };
    const onSelectionChange = (part) => {
        part.isHighlighted = part.isSelected;
    };

    const initDiagram = (divId) => {
        const diagram = new go.Diagram(divId, {
            layout: new go.TreeLayout({
                angle: 90,
                nodeSpacing: 20,
                layerSpacing: 50,
                layerStyle: go.TreeLayout.LayerUniform,
                treeStyle: go.TreeStyle.LastParents,
                alternateAngle: 90,
                alternateLayerSpacing: 35,
                alternateAlignment: go.TreeAlignment.BottomRightBus,
                alternateNodeSpacing: 20
            }),
            'toolManager.hoverDelay': 100,
            linkTemplate: createLinkTemplate(),
            model: new go.TreeModel({nodeKeyProperty: 'id'}),
        });

        diagram.nodeTemplate = createNodeTemplate();

        familyData.forEach(async (node) => {
            const photoUrl = await getPhotoId(node.urlId);
            if (photoUrl) {
                diagram.model.set(node, "imageUrl", photoUrl);
            }
            diagram.model.addNodeData(node);
        });

        diagram.addDiagramListener('InitialLayoutCompleted', () => {
            const root = diagram.findNodeForKey("King George V");
            if (!root) return;
            diagram.scale = 0.6;
            diagram.scrollToRect(root.actualBounds);
        });

        document.getElementById("zoomToFit").addEventListener("click", () => diagram.commandHandler.zoomToFit());

        document.getElementById("centerRoot").addEventListener("click", () => {
            diagram.scale = 1;
            diagram.commandHandler.scrollToPart(diagram.findNodeForKey("King George V"));
        });

        return diagram;
    };

    const diagramRef = useRef(null);

    const FamilyTreeDiagram = () => {

        useEffect(() => {
            const diagram = initDiagram(diagramRef.current.id);

            return () => diagram.clear();
        }, []);

        return (
            <div>
                <div id="myDiagramDiv" ref={diagramRef}
                     style={{width: '95.5%', margin: '0 auto', height: '600px'}}></div>
                <div style={{width: '95.5%', display: 'flex', justifyContent: 'space-between', margin: '10px auto'}}>
                    <button id="zoomToFit">Zoom to Fit</button>
                    <button id="centerRoot">Center on Root</button>
                </div>
            </div>
        );
    };

    const handleDOMContentLoaded = () => {
        setTimeout(() => {
            if (diagramRef.current) {
                initDiagram(diagramRef.current.id);
            }
        }, 300);
    };

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
        return () => {
            window.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
        };
    }, []);

    const getPhotoId = async (id) => {
        if (!id) return null;

        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });

            // Convert blob to object URL
            return URL.createObjectURL(response.data);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }


    return (
        <div>
            <div className="wrapperPartners">
                <InviteCounts counts={counts}/>
            </div>
            <FamilyTreeDiagram/>
        </div>
    );
}

export default GraphicalRepresentation;
  