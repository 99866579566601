// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/invoice/PDF.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagePdfInvoice {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
}`, "",{"version":3,"sources":["webpack://./src/Agreement/Pages/invoice.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;AACJ","sourcesContent":[".imagePdfInvoice{\n    background-image: url(../../images/invoice/PDF.png);\n    width: 50px;\n    height: 50px;\n    background-size: 50px 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
