import React, { useEffect, useState } from "react";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { ReactComponent as Agreement } from "../../images/Navigation/Agreement.svg";
import MyModal from "./MoadlWindowInvoice";
import {
    Dialog,
    DialogTitle,
    DialogContent,
  } from "@mui/material";
function InvoiceAgreement() {
  const [content, setContent] = useState([]);
  const [pageSettings, setPageSettings] = useState({});
  const [page, setPage] = useState(0);
  const [row, setRow] = useState([]);
  const [pagination, setPagination] = useState();
  const pageSize = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idDoc, setIdDoc] = useState("");
  const { t, i18n } = useTranslation();
  const [openReject, setOpenReject] = useState(false)
  const [verificationId, setVerificationId] = useState()
  const [valueReject, setValueReject] = useState('')

  useEffect(() => {
    handleConsultantAgreement();
  }, [page]);

  const handleConsultantAgreement = async () => {
    const param = {
      page: page,
      size: 10,
    };

    const queryString = new URLSearchParams(param).toString();
    const apiUrl =
      urlFromTemplate(ENDPOINTS.INVOICE) + `/pending?${queryString}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setContent(response.data.content);
      setPagination(response.data.totalElements);
    } catch (error) {
      console.error(error);
    }
  };

  //Форматирование даты

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  // Открытие PDF

  const handleOpenAgreement = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // Подтвердить инвойс


  const handleUserWithdtaw = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `/admin-confirm/${id}`
    const payload = {
        invoiceId: id
    }
    const param = {

    }
    try{
        const response = await axios.post(apiUrl, param, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
        })
    } catch (error) {
        console.error(error)
    } finally {
        await handleConsultantAgreement();
        handleCloseReject()
    }

  }

  // Окно отказа 

  const handleClickOpenReject = (id) => {
    setOpenReject(true)
    setVerificationId(id)
  }

  const handleCloseReject = () => {
    setOpenReject(false)
  }

  const handleChangeReject = (event) => {
    const newValue = event.target.value
    setValueReject(newValue)
  }

  const handleUserDecline = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE) + `/admin-decline`
    const  payload = {
        invoiceId: id,
        reason: valueReject,
    }
    try{
        const response = await axios.post(apiUrl, payload, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
        })
    } catch (error) {
        console.error(error)
    } finally {
        setValueReject('');
        await handleConsultantAgreement();
        handleCloseReject()
    }
  }

  return (
    <div className="mainPageBody" style={{ width: "87%", margin: "10px auto" }}>
      <div className="wrapperEvents">
        <div className="nameWrapper">
          <span className="nameText">{t("invoice")}</span>
        </div>
      </div>
      <div
        style={{
          maxWidth: "900px",
          display: "flex",
          justifyContent: "center",
          margin: "10px auto",
        }}
      ></div>
      <div className="wrapperIncomeTableTransaction">
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead
              style={{ background: "#F7F9FC", border: "1px solid white" }}
            >
              <TableRow style={{ border: "none" }}>
                <TableCell
                  style={{
                    border: "none",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#64748B",
                  }}
                >
                  <b>{t("AgreementTime")}</b>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#64748B",
                  }}
                >
                  <b>{t("AgreementUserName")}</b>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#64748B",
                  }}
                  align="left"
                >
                  <b>{t("invoice")}</b>
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#64748B",
                  }}
                  align="left"
                >
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "#64748B",
                  }}
                  align="left"
                >
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content.length > 0 &&
                content.map((row) => (
                  <TableRow
                    key={row.is}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        paddingLeft: "16px",
                        padding: "25px",
                        fontFamily: "Helvetica Neue",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                      component="th"
                      scope="row"
                    >
                      {formatDate(row.dateTime)}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#10B981",
                        paddingLeft: "16px",
                        padding: "25px",
                        fontFamily: "Helvetica Neue",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                      align="left"
                    >
                      {row.userLogin}
                    </TableCell>
                    <TableCell
                      style={{
                        paddingLeft: "16px",
                        padding: "25px",
                        fontFamily: "Helvetica Neue",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                      component="th"
                      scope="row"
                    >
                      <MyModal content={row.confirmationUrlIds} />
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        onClick={() => handleClickOpenReject(row.id)}
                        variant="contained"
                        color="error"
                      >
                        {" "}
                        {t("TransactionsButtonFalse")}{" "}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => handleUserWithdtaw(row.id)}
                        variant="contained"
                        color="success"
                      >
                        {" "}
                        {t("TransactionsButtonTrue")}{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={false}
            component="div"
            count={pagination}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </div>
      <Dialog open={openReject} onClose={handleCloseReject} maxWidth="lg">
        <DialogTitle>
            {t('reason')}
        </DialogTitle>
        <DialogContent>
        <textarea 
        value={valueReject}
        onChange={(e) => handleChangeReject(e)}
        style={{width:'500px', height:'100px'}}>

        </textarea>
        </DialogContent>
        <Button onClick={() => handleUserDecline(verificationId)}>
            {t('send')}
        </Button>
      </Dialog>
    </div>
  );
}

export default InvoiceAgreement;
