import React, {useEffect, useState} from "react";
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import WalletPendingTable from "./WalletPendingTable";
import SystemWalletsTable from "./SystemWalletsTable";


function WalletPage() {

    const [value, setValue] = useState('1');
    const {t, i18n} = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="wrapperEvents">
                <div className="nameWrapper">
                    <span className="nameText">{t("WalletManagement")}</span>
                </div>
            </div>
            <div
                style={{
                    maxWidth: "900px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px auto",
                }}
            >
            </div>
            <Box sx={{width: "100%", typography: "body1"}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                                 style={{justifyContent: 'center'}}>
                            <Tab label={t('SystemWallets')} value="1"/>
                            <Tab label={t('Update')} value="2"/>
                            <Tab label={t('Delete')} value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><SystemWalletsTable isUpdate={true} urlPostfix={'update'}/></TabPanel>
                    <TabPanel value="2"><WalletPendingTable isUpdate={true} urlPostfix={'update'}/></TabPanel>
                    <TabPanel value="3"><WalletPendingTable isUpdate={false} urlPostfix={'delete'}/></TabPanel>
                </TabContext>
            </Box>
        </>
    )
}

export default WalletPage