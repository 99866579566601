import React, {useEffect, useRef, useState} from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ReactComponent as Home} from "../images/Navigation/homeNav.svg";
import {ReactComponent as Cabinet} from "../images/Navigation/cabinetClient.svg";
import {ReactComponent as Profile} from "../images/Navigation/profileNav.svg";
import {ReactComponent as Exit} from "../images/Navigation/exitNav.svg";
import {ReactComponent as NavigationArrow} from "../images/Navigation/navigationArrow.svg";
import {ReactComponent as Structure} from "../images/Navigation/structure.svg";
import {ReactComponent as Handshake} from "../images/Navigation/handshake.svg";
import {ReactComponent as Events} from "../images/Navigation/events.svg";
import {ReactComponent as Transfer} from "../images/Navigation/transfer.svg";
import {ReactComponent as Verification} from "../images/Navigation/verification.svg";
import {ReactComponent as Agreement} from "../images/Navigation/Agreement.svg";
import {ReactComponent as Invoice} from "../images/Navigation/invoice.svg";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {useTranslation} from "react-i18next";

import axios from "axios";
import "../Style/Navigation/Navigation.scss";
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SearchIcon from "@mui/icons-material/Search";

function Navigation({isSmallScreen}) {
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const data = useSelector((state) => state.OpenNavBar.dataNav);
    const [navigateLogo, setNavigateLogo] = useState(window.location.pathname);
    let params = "";
    const {t, i18n} = useTranslation();
    const structureButtonRef = useRef(null);
    const [hoverPosition, setHoverPosition] = useState({top: 0, left: 0});
    const [isHovered, setIsHovered] = useState(false);
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleExit = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/logout`;
        await axios.post(apiUrl, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Credentials": "true",
            },
        });
    };

    const handleMouseEnter = () => {
        if (structureButtonRef.current) {
            const rect = structureButtonRef.current.getBoundingClientRect();
            setHoverPosition({top: rect.top, left: rect.right});
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        params = window.location.pathname;

        switch (params) {
            case "/mainpage":
                setSelectedIndex(1);
                break;
            case "/mainpage/role":
                setSelectedIndex(2);
                break;
            case "/mainpage/searchusers":
                setSelectedIndex(3);
                break;
            case "/mainpage/invite-structure":
                setSelectedIndex(4);
                break;
            case "/mainpage/graphical-structure":
                setSelectedIndex(5);
                break;
            case "/mainpage/partnership":
                setSelectedIndex(6);
                break;
            case "/mainpage/events":
                setSelectedIndex(7);
                break;
            case "/mainpage/transfer":
                setSelectedIndex(8);
                break;
            case "/mainpage/verification":
                setSelectedIndex(9);
                break;
            case "/mainpage/agreement":
                setSelectedIndex(10);
                break;
            case "/mainpage/invoice":
                setSelectedIndex(11);
                break;
        }
    }, []);

    return (
        <>
            {!isSmallScreen && (
                <>
                    <Drawer
                        open={!isSmallScreen}
                        sx={{
                            width: "301px",
                            backgroundColor: "#1C2434",
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                // width: drawerWidth,
                                boxSizing: "border-box",
                                position: "sticky",
                                top: "0px",
                                display: "flex",
                                // justifyContent: "space-between",
                                flexDirection: "column",
                                backgroundColor: "#1C2434",
                                height: "100vh",
                                zIndex: "9",
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <div className="logoNavigationImageWrapper"></div>
                        <List
                            style={{
                                width: "230px",
                                backgroundColor: "#1C2434",
                                color: "white",
                                margin: "0 auto",
                            }}
                        >
                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0"}}
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}
                                component={Link}
                                to="/mainpage"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Home
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("lorem")}/>
                                {selectedIndex != 0 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0"}}
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                                component={Link}
                                to="/mainpage"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Cabinet
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("cabinetClient")}/>
                                {selectedIndex != 1 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0"}}
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                                component={Link}
                                to="./role"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Profile
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("profile")}/>
                                {selectedIndex != 2 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0"}}
                                selected={selectedIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}
                                component={Link}
                                to="./searchusers"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <SearchIcon
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("bills")}/>
                                {selectedIndex != 3 && <NavigationArrow/>}
                            </ListItemButton>

                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0", color: "white"}}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                // selected={selectedIndex === 1}
                                // onClick={(event) => handleListItemClick(event, 1)}
                                onClick={handleClick}
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Structure
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("CabinetPartnerNavStructureMain")}/>
                                {open ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse
                                style={{display: "flex", justifyContent: "center"}}
                                in={open}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List
                                    style={{display: "flex", justifyContent: "center"}}
                                    component="div"
                                    disablePadding
                                >
                                    <ListItemButton
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "white",
                                        }}
                                        sx={{pl: 4}}
                                        selected={selectedIndex === 4}
                                        onClick={(event) => handleListItemClick(event, 4)}
                                        component={Link}
                                        to="./invite-structure "
                                    >
                                        <ListItemText
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                textAlign: "center",
                                            }}
                                            primary={t("CabinetPartnerNavStructureTable")}
                                        />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItemButton
                                        sx={{pl: 4, color: "white"}}
                                        selected={selectedIndex === 5}
                                        onClick={(event) => handleListItemClick(event, 5)}
                                        component={Link}
                                        to="./graphical-structure"
                                    >
                                        <ListItemText
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                textAlign: "center",
                                            }}
                                            primary={t("CabinetPartnerNavStructureGraph")}
                                        />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton
                                style={{display: "flex", paddingLeft: "0", color: "white"}}
                                selected={selectedIndex === 6}
                                onClick={(event) => handleListItemClick(event, 6)}
                                component={Link}
                                to="./partnership"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Handshake
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("partners")}/>
                                {selectedIndex != 6 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 7}
                                onClick={(event) => handleListItemClick(event, 7)}
                                component={Link}
                                to="./events"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Events
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("events")}/>
                                {selectedIndex != 7 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 8}
                                onClick={(event) => handleListItemClick(event, 8)}
                                component={Link}
                                to="./transfer"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Transfer
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("transfers")}/>
                                {selectedIndex != 8 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 9}
                                onClick={(event) => handleListItemClick(event, 9)}
                                component={Link}
                                to="./verification"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Verification
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "none",
                                            stroke: "white",
                                            strokeWidth: "5px",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("verification")}/>
                                {selectedIndex != 9 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 10}
                                onClick={(event) => handleListItemClick(event, 10)}
                                component={Link}
                                to="./agreement"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center" }}
                                >
                                    <Agreement
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "rgba(170,170,170,0.17)",
                                            stroke: "white",
                                            strokeWidth: "5px",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("agreement")}/>
                                {selectedIndex != 10 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 11}
                                onClick={(event) => handleListItemClick(event, 11)}
                                component={Link}
                                to="./invoice"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Invoice
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "none",
                                            stroke: "white",
                                            strokeWidth: "5px",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("invoice")}/>
                                {selectedIndex != 11 && <NavigationArrow/>}
                            </ListItemButton>
                            <ListItemButton
                                style={{ display: "flex", paddingLeft: "0",  color: "white" }}
                                selected={selectedIndex === 12}
                                // onClick={(event) => handleListItemClick(event, 4)}
                                onClick={handleExit}
                                component={Link}
                                to="/"
                            >
                                <ListItemIcon
                                    style={{display: "flex", justifyContent: "center"}}
                                >
                                    <Exit
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            stroke: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("exit")}/>
                                {selectedIndex != 12 && <NavigationArrow/>}
                            </ListItemButton>
                        </List>
                    </Drawer>
                </>
            )}
        </>
    );
}

export default Navigation;
