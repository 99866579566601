import React from "react";
import '../../Style/Body.scss'
import {useSelector} from "react-redux";
import ProjectDetails from "./ProjectDetails";

function Body () {

    useSelector((state) => state.OpenNavBar.dataNav)

    return(
        <>
        <div className="mainPageBody">
            <ProjectDetails/>
        </div>
        </>
    )
}

export default Body