import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "../Style/NameCabinet.scss";
import "../Style/Events/Events.scss";
import urlFromTemplate from "../configs/url";
import { ENDPOINTS } from "../configs/endpoints";
import axios from "axios";
import {
  Button,
  Checkbox,
  FormControl,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Placemark, YMaps, Map } from "@pbe/react-yandex-maps";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

function Events() {
  const { t, i18n } = useTranslation();
  const [trainingEvents, setTrainingEvents] = useState([]);
  const [training, setTraining] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [inputvalueName, setInputvalueName] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorElRole, setAnchorElRole] = useState(null);
  const openRole = Boolean(anchorElRole);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [trainingIndex, setTrainingIndex] = useState(null);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [filterChangeModal, setFilterChangeModal] = useState();
  const [resultChange, setResultChange] = useState();
  const [searchUser, setSearchUser] = useState("");
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState();
  const handelOpenModalChange = (item) => {
    setOpenModalChange(true);
    filteredTrainingChange(item.id);
  };
  const handelCloseModalChange = () => setOpenModalChange(false);
  const handleOpenAddUserModal = () => {
    setOpenModalAddUser(true);
    setOpenModalChange(false);
  };
  const handleCloseAddUserModal = () => setOpenModalAddUser(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    handleGetTraningEvent();
  }, []);

  const handleGetTraningEvent = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING_EVENT);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setTrainingEvents(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      handelGetTraining();
    }
  };

  const handelGetTraining = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setTraining(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(trainingEvents);
    console.log(training);
  }, [training]);

  const [result, setResult] = useState({
    trainingId: null,
    dateTime: null,
    description: "",
    lat: null,
    lng: null,
  });

  const handleChangeName = (field, item) => {
    const newValue = item.id;
    setSelectedRowId(item.id);
    setResult((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleChangeDescription = (field, event) => {
    const newValue = event.target.value;
    setResult((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleDateFieldChangeStart = (field, newValue) => {
    const formattedDate = newValue;
    console.log(formattedDate);
    setResult((prevState) => ({
      ...prevState,
      [field]: formattedDate,
    }));
  };

  const handleClickRole = (event, rowId) => {
    setAnchorElRole(event.currentTarget);
  };

  const handleCloseRole = () => {
    setAnchorElRole(null);
  };

  const handleMapClick = (e) => {
    const coords = e.get("coords");
    console.log("coords", coords);
    setResult((prevState) => ({
      ...prevState,
      lat: coords[0],
      lng: coords[1],
    }));
    console.log(result);
  };

  const handleCreateEvent = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING_EVENT);
    try {
      const response = await axios.post(apiUrl, result, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeEvent = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING_EVENT);
    try {
      const response = await axios.put(apiUrl, resultChange, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const filteredTraining = (rowId) => {
    return training.filter((item) => item.id === rowId.trainingId);
  };

  const filteredTrainingChange = (rowId) => {
    const selectedItem = trainingEvents.find((item) => item.id === rowId);
    if (selectedItem) {
      setResultChange({
        id: selectedItem.id,
        trainingId: selectedItem.trainingId,
        dateTime: new Date(selectedItem.dateTime),
        description: selectedItem.description,
        lat: selectedItem.lat,
        lng: selectedItem.lng,
      });
    }

    console.log(resultChange);
  };

  useEffect(() => {
    console.log(result);
  });

  const handleChangeNameChange = (field, item) => {
    const newValue = item.id;
    setSelectedRowId(item.id);
    setResultChange((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleChangeDescriptionChange = (field, event) => {
    const newValue = event.target.value;
    setResultChange((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleDateFieldChangeStartChange = (field, newValue) => {
    const formattedDate = newValue;
    console.log(formattedDate);
    setResultChange((prevState) => ({
      ...prevState,
      [field]: formattedDate,
    }));
  };

  const handleMapClickChange = (e) => {
    const coords = e.get("coords");
    console.log("coords", coords);
    setResultChange((prevState) => ({
      ...prevState,
      lat: coords[0],
      lng: coords[1],
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const handleSearchChange = (event) => {
    setSearchUser(event.target.value);
  };

  const handleSearchUser = useCallback(async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/${searchUser}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
      setUser([response.data]);
      setUserId(response.data.id);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [searchUser]);

  const handleSearchButtonClick = () => {
    handleSearchUser();
  };

  const handleAddUser = async (item) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER_TRAINING_EVENT);
    console.log(item);
    const payload = {
      userId: userId,
      trainingEventId: resultChange.trainingId,
    };
    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <>
        <div className="wrapperEvents">
          <div className="nameWrapper">
            <span className="nameText">{t("events")}</span>
          </div>
          <Button onClick={handleOpen}>
            <AddIcon />
          </Button>
        </div>
        <div>
          {trainingEvents.map((item, index) => (
            <div key={index}>
              {filteredTraining(item).map((rewardItem, rewardIndex) => (
                <table
                  className="iksweb"
                  style={{ width: "90%", margin: "0 auto" }}
                >
                  <tbody>
                    <tr onClick={() => handelOpenModalChange(item)}>
                      <td style={{ width: "40%" }}>
                        <MenuItem> {rewardItem.ruTraining} </MenuItem>
                      </td>
                      <td style={{ width: "30%" }}>
                        <MenuItem> {rewardItem.enTraining} </MenuItem>
                      </td>
                      <td style={{ width: "30%" }}>
                        <MenuItem> {formatDate(item.dateTime)}</MenuItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          ))}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("EventModalWindowTitle")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignContent: "space-between",
              }}
            >
              <Button
                id="fade-button"
                aria-controls={openRole ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openRole ? "true" : undefined}
                onClick={(e) => handleClickRole(e, null)}
                style={{
                  width: "49%",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "black",
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {result
                  ? training.map((item) => (
                      <React.Fragment>
                        {item.id === result.trainingId
                          ? item.ruTraining
                          : //  `${t('EventModalWindowName')}`
                            null}
                      </React.Fragment>
                    ))
                  : null}
              </Button>
              <Menu
                label={t("EventModalWindowTextFieldName")}
                id="fade-menu"
                MenuListProps={{ "aria-labelledby": "fade-button" }}
                anchorEl={anchorElRole}
                open={openRole}
                onClose={handleCloseRole}
                TransitionComponent={Fade}
                style={{ width: "49%" }}
                // onChange={handleChangeName}
              >
                {training.map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={() => handleChangeName("trainingId", item)}
                  >
                    {item.ruTraining}
                  </MenuItem>
                ))}
              </Menu>
              <DateTimePicker
                views={["day", "month", "year", "hours", "minutes"]}
                sx={{ width: "49%" }}
                label={t("EventModalWindowTextFieldDate")}
                value={result.dateTime ? dayjs(result.dateTime) : null}
                onChange={(newValue) =>
                  handleDateFieldChangeStart("dateTime", newValue)
                }
                // onChange={(newValue) => handleChangeDate('dateTime', newValue)}
              />
              <TextField
                label={t("EventModalWindowTextFieldDesc")}
                style={{ width: "100%", marginTop: "10px" }}
                value={result.description}
                onChange={(e) => handleChangeDescription("description", e)}
              />
            </div>
            <div className="contactsWrapper" style={{ flex: "0 0 60%" }}>
              <div className="nameWrapper" style={{ marginBottom: "20px" }}>
                <span style={{ marginLeft: "0px" }} className="nameText">
                  {t("CreateProjectLocation")}
                </span>
              </div>
              <YMaps>
                <div className="admin-project-map">
                  <Map
                    onClick={handleMapClick}
                    defaultState={{ center: [48.86, 2.35], zoom: 5 }}
                    style={{ width: "100%", height: "100%" }}
                  >
                    {result.lat && result.lng && (
                      <Placemark geometry={[result.lat, result.lng]} />
                    )}
                  </Map>
                </div>
              </YMaps>
            </div>
            <Button onClick={() => handleCreateEvent()}>Создать</Button>
          </Box>
        </Modal>

        <Modal
          open={openModalChange}
          onClose={handelCloseModalChange}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("EventModalWindowTitle")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignContent: "space-between",
              }}
            >
              <Button
                id="fade-button"
                aria-controls={openRole ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openRole ? "true" : undefined}
                onClick={(e) => handleClickRole(e, null)}
                style={{
                  width: "49%",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "black",
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {resultChange
                  ? training.map((item) => (
                      <React.Fragment>
                        {item.id === resultChange.trainingId
                          ? item.ruTraining
                          : null}
                      </React.Fragment>
                    ))
                  : null}
              </Button>
              <Menu
                label={t("EventModalWindowTextFieldName")}
                id="fade-menu"
                MenuListProps={{ "aria-labelledby": "fade-button" }}
                anchorEl={anchorElRole}
                open={openRole}
                onClose={handleCloseRole}
                TransitionComponent={Fade}
                style={{ width: "49%" }}
                // onChange={handleChangeName}
              >
                {training.map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={() => handleChangeNameChange("trainingId", item)}
                  >
                    {item.ruTraining}
                  </MenuItem>
                ))}
              </Menu>
              <DateTimePicker
                views={["day", "month", "year", "hours", "minutes"]}
                sx={{ width: "49%" }}
                label={t("EventModalWindowTextFieldDate")}
                value={resultChange ? result.dateTime : null}
                onChange={(newValue) =>
                  handleDateFieldChangeStartChange("dateTime", newValue)
                }
              />
              <TextField
                label={t("EventModalWindowTextFieldDesc")}
                style={{ width: "100%", marginTop: "10px" }}
                value={resultChange ? resultChange.description : null}
                onChange={(e) =>
                  handleChangeDescriptionChange("description", e)
                }
              />
            </div>
            <div className="contactsWrapper" style={{ flex: "0 0 60%" }}>
              <div className="nameWrapper" style={{ marginBottom: "20px" }}>
                <span style={{ marginLeft: "0px" }} className="nameText">
                  {t("CreateProjectLocation")}
                </span>
              </div>
              <YMaps>
                <div className="admin-project-map">
                  <Map
                    onClick={handleMapClickChange}
                    defaultState={{ center: [48.86, 2.35], zoom: 5 }}
                    style={{ width: "100%", height: "100%" }}
                  >
                    {resultChange
                      ? resultChange.lat &&
                        resultChange.lng && (
                          <Placemark
                            geometry={[resultChange.lat, resultChange.lng]}
                          />
                        )
                      : null}
                  </Map>
                </div>
              </YMaps>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => handleChangeEvent()}>
                {" "}
                {t("MainPageProjectButtonChange")}{" "}
              </Button>
              <Button onClick={() => handleOpenAddUserModal()}>
                {t("MainPageProjectButtonAd")}
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openModalAddUser}
          onClose={handleCloseAddUserModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("EventModalWindowTitle")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignContent: "space-between",
              }}
            >
              <div className="searchWrapper">
                <div className="listSearchBlock" style={{ width: "100%" }}>
                  <TextField
                    autoComplete="off"
                    placeholder="Поиск"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", margin: "10px" }}
                    value={searchUser}
                    onChange={handleSearchChange}
                    InputProps={{
                      style: { height: "40px", borderRadius: "10px" },
                      startAdornment: (
                        <SearchIcon
                          style={{ cursor: "pointer" }}
                          onClick={handleSearchButtonClick}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Photo</TableCell>
                      <TableCell align="right">id</TableCell>
                      <TableCell align="right">login</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.urlId}
                        </TableCell>
                        <TableCell align="right">{item.id}</TableCell>
                        <TableCell align="right">{item.login}</TableCell>
                        <TableCell align="right">{item.statusName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button onClick={() => handleAddUser(user)}>
              {" "}
              {t("MainPageProjectButtonAd")}{" "}
            </Button>
          </Box>
        </Modal>
      </>
    </LocalizationProvider>
  );
}

export default Events;
