import React from "react";
import {useTranslation} from "react-i18next";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import ConsultantAgreement from "./Pages/Consultant";
import ProjectAgreement from "./Pages/Project";
import WithdrawAgreement from "./Pages/Withdraw";
import '../Style/TabMui.scss'

function Agreement() {
    const {t} = useTranslation();
    const [value, setValue] = React.useState("1");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className="wrapperEvents">
                <div className="nameWrapper">
                    <span className="nameText">{t("agreement")}</span>
                </div>
            </div>
            <div
                style={{
                    maxWidth: "900px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px auto",
                }}
            >
            </div>
            <Box sx={{width: "100%", typography: "body1"}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                                 style={{justifyContent: 'center'}}>
                            <Tab label={t('AgreementConsultant')} value="1"/>
                            <Tab label={t('AgreementProject')} value="2"/>
                            <Tab label={t('AgreementWithdraw')} value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1"><ConsultantAgreement/></TabPanel>
                    <TabPanel value="2"><ProjectAgreement/></TabPanel>
                    <TabPanel value="3"><WithdrawAgreement/></TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default Agreement;
