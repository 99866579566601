// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperBonusPartner {
  background-color: white;
  border-radius: 8px;
  min-height: 200px;
  margin: 20px auto 20px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
}

.inviteCountsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrapperMotivationPartner {
  border-radius: 20px;
  min-height: 200px;
  margin: 20px auto 20px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 0px;
  align-items: center;
  flex-wrap: wrap;
}

.motivationCardWrapper {
  width: 48%;
  background-color: white;
  border-radius: 20px;
  min-height: 430px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 15px;
}

@media screen and (max-width: 1200px) {
  .wrapperMotivationPartner {
    flex-direction: column;
  }
  .motivationCardWrapper {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .inviteCountsWrapper {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Partners/BonusPartner.scss"],"names":[],"mappings":"AAAA;EAEE,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AAAF;;AAGA;EAEE,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,UAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AADF;;AAIA;EACE;IACE,sBAAA;EADF;EAGA;IACE,WAAA;EADF;AACF;AAIA;EACE;IACE,sBAAA;EAFF;AACF","sourcesContent":[".wrapperBonusPartner {\n  // width: 90%;\n  background-color: white;\n  border-radius: 8px;\n  min-height: 200px;\n  margin: 20px auto 20px;\n  overflow: hidden;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: center;\n  padding-top: 20px;\n  padding-bottom: 0px;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inviteCountsWrapper{\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.wrapperMotivationPartner {\n  // width: 94%;\n  border-radius: 20px;\n  min-height: 200px;\n  margin: 20px auto 20px;\n  overflow: hidden;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-between;\n  padding-top: 20px;\n  padding-bottom: 0px;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.motivationCardWrapper {\n  width: 48%;\n  background-color: white;\n  border-radius: 20px;\n  min-height: 430px;\n  overflow: hidden;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  padding: 15px;\n}\n\n@media screen and (max-width: 1200px) {\n  .wrapperMotivationPartner {\n    flex-direction: column;\n  }\n  .motivationCardWrapper {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width:'650px') {\n  .inviteCountsWrapper{\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
