import React from "react";
import '../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function CreateProjectName({step}) {
    const { t, i18n } = useTranslation();

    return (
        <div className="nameWrapper" style={{marginBottom: '15px'}}>
        <span className="nameText">
           {t('CreateProjectTitleSteps')} {step}
        </span>
        </div>
    )
}

export default CreateProjectName