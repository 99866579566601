import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import urlFromTemplate from "../configs/url";
import { ENDPOINTS } from "../configs/endpoints";
import axios from "axios";
import TablePagination from "@mui/material/TablePagination";

function Verification() {
  const { t } = useTranslation();
  const [content, setContent] = useState([]);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(0)
  const pageSize = 10;
  const [photoCab, setPhotoCab] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openReject, setOpenReject] = useState(false)
  const [valueReject, setValueReject] = useState('')
  const [verificationId, setVerificationId] = useState()

  const handleGetPending = async () => {
    const param = {
      page: page,
      size: 10,
    }
    const queryString = new URLSearchParams(param).toString();
    const apiUrl = urlFromTemplate(ENDPOINTS.VERIFICATION) + `/pending` + `?${queryString}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setContent(response.data.content)
      setPagination(response.data.totalElements);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetPending();
  }, []);

  useEffect(() => {
    content.forEach((row) => {
      getPhotoId(row.urlId, row.verificationId);
    });
  }, [content]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const handleUserWithdraw = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.VERIFICATION) + `/confirm/${id}`;
    const payload = {
    };
    try {
      await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleGetPending();
    }
  };

  const getPhotoId = async (urlId, verificationId) => {
    if (!urlId) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${urlId}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });

      setPhotoCab((prevState) => ({
        ...prevState,
        [verificationId]: URL.createObjectURL(response.data),
      }));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${urlId}`, error);
      return null;
    }
  };

  const handleUserDecline = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.VERIFICATION) + `/decline`;
    const payload = {
        verificationId: id,
        reason: valueReject,
    };
    try {
      await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      await handleGetPending();
      handleCloseReject();
    }
  };

  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClickOpenReject = (id) => {
    setOpenReject(true)
    setVerificationId(id)
  }

  const handleCloseReject = () => {
    setOpenReject(false)
  }

  const handleChangeReject = (event) => {
    const newValue = event.target.value
    setValueReject(newValue)
  }

  return (
    <>
      <div className="wrapperEvents">
        <div className="nameWrapper">
          <span className="nameText">{t("verification")}</span>
        </div>
      </div>
      <div
        style={{
          maxWidth: "900px",
          display: "flex",
          justifyContent: "center",
          margin: "10px auto",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">Login</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Photo</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content.map((row) => (
                <TableRow
                  key={row.verificationId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.verificationId}
                  </TableCell>
                  <TableCell align="right">{row.userLogin}</TableCell>
                  <TableCell align="right">
                    {formatDate(row.dateTime)}
                  </TableCell>
                  <TableCell align="right">
                    <div
                      className="myAccountImageLogin"
                      style={{
                        backgroundImage: `url(${photoCab[row.verificationId]})`,
                        cursor: "pointer",
                        width:'50px',
                        height:'50px',
                        backgroundSize:'100% 100%'
                      }}
                      onClick={() => handleClickOpen(photoCab[row.verificationId])}
                    ></div>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => handleClickOpenReject(row.verificationId)}
                        // onClick={handleClickOpenReject}
                      variant="contained"
                      color="error"
                    >
                      {t("TransactionsButtonFalse")}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => handleUserWithdraw(row.verificationId)}
                      variant="contained"
                      color="success"
                    >
                      {t("TransactionsButtonTrue")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={false}
              component="div"
              count={pagination}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
          />
        </TableContainer>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={openReject} onClose={handleCloseReject} maxWidth="lg">
        <DialogTitle>
            Укажите причину отказа
        </DialogTitle>
        <DialogContent>
        <textarea 
        value={valueReject}
        onChange={(e) => handleChangeReject(e)}
        style={{width:'500px', height:'100px'}}>

        </textarea>
        </DialogContent>
        <Button onClick={() => handleUserDecline(verificationId)}>
            Отправить
        </Button>
      </Dialog>
    </>
  );
}

export default Verification;
