// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchUserWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.searchWrapper {
  width: 90%;
  background-color: white;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.usersWrapper {
  margin-top: 10px;
  width: 90%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Style/SearchUsers/SearchUsers.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".searchUserWrapper{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.searchWrapper{\n    width: 90%;\n    background-color: white;\n    padding: 20px;\n    margin-bottom: 10px;\n    border-radius: 10px;\n}\n\n.usersWrapper{\n    margin-top: 10px;\n    width: 90%;\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
