import React, {useState} from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import CreateProjectName from "./CreateProjectName";
import {useDropzone} from "react-dropzone";
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function CreateProject() {

    const theme = useTheme();
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [primaryImage, setPrimaryImage] = useState(null);
    const [primaryImageName, setPrimaryImageName] = useState('');

    const [additionalImage, setAdditionalImage] = useState(null);
    const [additionalImageName, setAdditionalImageName] = useState('');

    const [document, setDocument] = useState(null);
    const [documentName, setDocumentName] = useState('');

    const [isSecondStep, setIsSecondStep] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const { t, i18n } = useTranslation();


    const onDropPrimaryImage = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setPrimaryImage(file);
            setPrimaryImageName(file.name);
        }
    };

    const onDropAdditionalImage = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setAdditionalImage(file);
            setAdditionalImageName(file.name);
        }
    };

    const onDropDocument = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setDocument(file);
            setDocumentName(file.name);
        }
    };

    const {getRootProps: getRootPrimaryImageProps, getInputProps: getInputPrimaryImageProps} =
        useDropzone({onDrop: onDropPrimaryImage, accept: "image/*"});

    const {getRootProps: getRootAdditionalImageProps, getInputProps: getInputAdditionalImageProps} =
        useDropzone({onDrop: onDropAdditionalImage, accept: "image/*"});

    const {getRootProps: getRootDocumentProps, getInputProps: getInputDocumentProps} =
        useDropzone({onDrop: onDropDocument, accept: ".pdf,.doc,.docx,.txt"});

    const handleAddFile = async (file, type) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT) + `/file`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectId', projectId);
        formData.append('type', type);
        console.log("formData", formData)

        try {
            await axios.put(apiUrl, formData, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
            });
            alert('Файл был успешно добавлен')
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const [result, setResult] = useState({
        title: '',
        description: '',
        endDate: null,
        beginDate:null,
        poolSum: null,
        lat: null,
        lng: null
    });

    const handleFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        setResult((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    const handleDateFieldChange = (field, newValue) => {
        const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : null;
        console.log(formattedDate);
        setResult((prevState) => ({
            ...prevState,
            [field]: formattedDate,
        }));
    };

    const handleDateFieldChangeStart = (field, newValue) => {
        const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : null;
        console.log(formattedDate);
        setResult((prevState) => ({
            ...prevState,
            [field]: formattedDate,
        }));
    };

    const handleNumericFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        // Filter input to allow only digits and dot
        const floatRegex = /^[0-9]*\.?[0-9]*$/;
        if (newValue === '' || floatRegex.test(newValue)) {
            setResult((prevState) => ({...prevState, [field]: newValue}));
        }
    }

    const handleMapClick = (e) => {
        const coords = e.get('coords');
        console.log('coords', coords);
        setResult((prevState) => ({
            ...prevState,
            lat: coords[0],
            lng: coords[1],
        }));
    };

    const handleCreateProject = async (e) => {
        e.preventDefault();
        console.log('result', result)
        const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT);
        try {
            await axios.post(apiUrl, result, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then((response) => {
                setIsSecondStep(true);
                console.log(response.data.id);
                setProjectId(response.data.id);
            });
        } catch (error) {
            alert(error.response.data.message);
            console.error(error);
        }
    };

    const handleProjectNavigation = () => {
        // TODO: change to future project page
        navigate('/mainpage');
    }


    return (
        <>
            {!isSecondStep &&
                <>
                    <CreateProjectName step={t('CreateProjectTitleStepsFirst')}/>
                    <div className="projectWrapper">
                        <div className="projectContainer" style={{padding: '0px'}}>
                            <div className="projectPreviewWrapper" style={{padding: '0px'}}>
                                <div className="projectContainer" style={{padding: '0px', alignItems: 'flex-start'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <div className="contactsWrapper" style={{flex: '0 0 35%'}}>
                                            <div className="nameWrapper" style={{marginBottom: "10px"}}>
                                                <span style={{marginLeft: '0px'}} className="nameText">
                                                    {t('CreateProjectInfo')}
                                                </span>
                                            </div>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }} value='1'>
                                                <TextField sx={{marginTop: '10px', width: '100%'}} id="outlined-basic"
                                                           label={t('CreateProjectNameProject')}
                                                           variant="outlined" value={result.title}
                                                           onChange={handleFieldChange('title')}/>
                                                <TextField
                                                    sx={{marginTop: '10px', width: '100%'}}
                                                    id="outlined-basic"
                                                    label={t('CreateProjectDescriptionProject')}
                                                    variant="outlined"
                                                    value={result.description}
                                                    onChange={handleFieldChange('description')}
                                                    multiline
                                                    rows={5}
                                                />
                                                {/* <TextField
                                                    sx={{marginTop: '10px', width: '100%'}}
                                                    id="outlined-basic"
                                                    label={t('CreateProjectMinSum')}
                                                    variant="outlined"
                                                    value={result.minPrice}
                                                    onChange={handleNumericFieldChange('minPrice')}
                                                    inputProps={{
                                                        inputMode: 'decimal',
                                                        pattern: '[0-9]*[.]?[0-9]*'
                                                    }}
                                                />
                                                <TextField
                                                    sx={{marginTop: '10px', width: '100%'}}
                                                    id="outlined-basic"
                                                    label={t('CreateProjectMinSumSecond')}
                                                    variant="outlined"
                                                    value={result.repeatPrice}
                                                    onChange={handleNumericFieldChange('repeatPrice')}
                                                    inputProps={{
                                                        inputMode: 'decimal',
                                                        pattern: '[0-9]*[.]?[0-9]*'
                                                    }}
                                                />
                                                <TextField
                                                    sx={{marginTop: "10px", width: "100%"}}
                                                    id="outlined-select-currency"
                                                    select
                                                    label={t('CreateProjectWaysMain')}
                                                    value={result.contributionType}
                                                    onChange={handleFieldChange("contributionType")}
                                                >
                                                    {contributionTypes.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <DateTimePicker
                                                    views={["day", "month", "year"]}
                                                    sx={{marginTop: "10px", width: "100%"}}
                                                    label={t('CreateProjectDateStart')}
                                                    value={
                                                        result.beginDate
                                                            ? dayjs(result.beginDate)
                                                            : null
                                                    }
                                                    onChange={(newValue) =>
                                                        handleDateFieldChangeStart("beginDate", newValue)
                                                    }
                                                />
                                                <DateTimePicker
                                                    views={["day", "month", "year"]}
                                                    sx={{marginTop: "10px", width: "100%"}}
                                                    label={t('CreateProjectDate')}
                                                    value={
                                                        result.endDate
                                                            ? dayjs(result.endDate)
                                                            : null
                                                    }
                                                    onChange={(newValue) =>
                                                        handleDateFieldChange("endDate", newValue)
                                                    }
                                                />
                                                <TextField
                                                    sx={{marginTop: '10px', width: '100%'}}
                                                    id="outlined-basic"
                                                    label={t('CreateProjectSumPull')}
                                                    variant="outlined"
                                                    value={result.poolSum}
                                                    onChange={handleNumericFieldChange('poolSum')}
                                                    inputProps={{
                                                        inputMode: 'decimal',
                                                        pattern: '[0-9]*[.]?[0-9]*'
                                                    }}
                                                />
                                            </Box>
                                        </div>
                                        <div className="contactsWrapper" style={{flex: '0 0 60%'}}>
                                            <div className="nameWrapper" style={{marginBottom: "20px"}}>
                                                <span style={{marginLeft: '0px'}} className="nameText">
                                                    {t('CreateProjectLocation')}
                                                </span>
                                            </div>
                                            <YMaps>
                                                <div className="admin-project-map">
                                                    <Map
                                                        onClick={handleMapClick}
                                                        defaultState={{center: [48.86, 2.35], zoom: 5}}
                                                        style={{width: '100%', height: '100%'}}
                                                    >
                                                        {result.lat && result.lng && (
                                                            <Placemark geometry={[result.lat, result.lng]}/>
                                                        )}
                                                    </Map>
                                                </div>
                                            </YMaps>
                                        </div>
                                    </LocalizationProvider>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop:'20px'}}>
                                    <button className="yellowButton buttonBoldText" style=
                                        {{width: '100%', backgroundColor: 'rgba(49,150,250,0.85)', fontSize: '22px'}}
                                            onClick={handleCreateProject}>
                                        {t('CreateProjectMainButton')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }


            {isSecondStep &&
                <>
                    <CreateProjectName step={t('CreateProjectTitleStepsSecond')}/>
                    <div className="projectWrapper">
                        <div className="projectContainer" style={{padding: '0px'}}>
                            <div className="projectPreviewWrapper" style={{padding: '0px'}}>
                                <div className="projectContainer" style={{padding: '0px', alignItems: 'flex-start'}}>
                                    <div className="contactsWrapper" style={{flex: '0 0 100%'}}>
                                        <div className="nameWrapper" style={{marginBottom: "10px"}}>
                                            <span style={{marginLeft: '0px'}} className="nameText">
                                                {t('CreateProjectSecondStepImg')}
                                            </span>
                                        </div>

                                        <div style={{marginBottom: '20px'}}>
                                            <label
                                                style={{display: 'block', margin: '30px 0 10px 0', fontSize: '20px'}}>
                                                {t('CreateProjectSecondStepMainImg')}
                                                : <label style={{
                                                color: 'rgba(49,150,250,0.85)',
                                                fontSize: '20px'
                                            }}>{primaryImageName}</label>
                                            </label>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                margin: '20px 0'
                                            }}>
                                                <div {...getRootPrimaryImageProps({className: 'yellowButton buttonBoldText'})}
                                                     style={{
                                                         textAlign: 'center',
                                                         lineHeight: '40px',
                                                         minWidth: '100px',
                                                         backgroundColor: '#ababab'
                                                     }}>
                                                    <input {...getInputPrimaryImageProps()} style={{display: 'none'}}/>
                                                    {t('CreateProjectSecondStepButtonSelect')}
                                                </div>
                                                <button className="yellowButton buttonBoldText"
                                                        style={{
                                                            minWidth: '100px',
                                                        }}
                                                        onClick={() => handleAddFile(primaryImage, 'PRIMARY_IMAGE')}>
                                                    {t('CreateProjectSecondStepButtonInstall')}
                                                </button>
                                            </div>
                                        </div>

                                        <div style={{marginBottom: '20px'}}>
                                            <label
                                                style={{display: 'block', margin: '30px 0 10px 0', fontSize: '20px'}}>
                                                {t('CreateProjectSecondStepSecondImg')}
                                                : <label style={{
                                                color: 'rgba(49,150,250,0.85)',
                                                fontSize: '20px'
                                            }}>{additionalImageName}</label>
                                            </label>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                margin: '20px 0'
                                            }}>
                                                <div {...getRootAdditionalImageProps({className: 'yellowButton buttonBoldText'})}
                                                     style={{
                                                         textAlign: 'center',
                                                         lineHeight: '40px',
                                                         minWidth: '100px',
                                                         backgroundColor: '#ababab'
                                                     }}>
                                                    <input {...getInputAdditionalImageProps()}
                                                           style={{display: 'none'}}/>
                                                    {t('CreateProjectSecondStepButtonSelect')}
                                                </div>
                                                <button className="yellowButton buttonBoldText"
                                                        style={{
                                                            minWidth: '100px',
                                                        }}
                                                        onClick={() => handleAddFile(additionalImage, 'ADDITIONAL_IMAGE')}>
                                                    {t('CreateProjectSecondStepButtonAdd')}
                                                </button>
                                            </div>
                                        </div>

                                        <div style={{marginBottom: '20px'}}>
                                            <label
                                                style={{display: 'block', margin: '30px 0 10px 0', fontSize: '20px'}}>
                                                {t('CreateProjectSecondStepDocProject')}
                                                : <label style={{
                                                color: 'rgba(49,150,250,0.85)',
                                                fontSize: '20px'
                                            }}>{documentName}</label>
                                            </label>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                margin: '20px 0'
                                            }}>
                                                <div {...getRootDocumentProps({className: 'yellowButton buttonBoldText'})}
                                                     style={{
                                                         textAlign: 'center',
                                                         lineHeight: '40px',
                                                         minWidth: '100px',
                                                         backgroundColor: '#ababab'
                                                     }}>
                                                    <input {...getInputDocumentProps()} style={{display: 'none'}}/>
                                                    {t('CreateProjectSecondStepButtonSelect')}
                                                </div>
                                                <button className="yellowButton buttonBoldText"
                                                        style={{
                                                            minWidth: '100px',
                                                        }}
                                                        onClick={() => handleAddFile(document, 'DOCUMENT')}>
                                                   {t('CreateProjectSecondStepButtonInstall')}
                                                </button>
                                            </div>
                                            <div style={{display: 'flex'}}>
                                                <button className="yellowButton buttonBoldText" style=
                                                    {{
                                                        fontSize: '20px',
                                                        margin: '30px 0 0 0', // Using viewport height for margin
                                                        width: '100%', // Making the button width responsive
                                                        height: 'auto', // Ensuring height adjusts to content
                                                        backgroundColor: 'rgba(49, 150, 250, 0.85)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        padding: '10px 20px', // Adjust padding as needed for button content
                                                    }}
                                                        onClick={handleProjectNavigation}>
                                                   {t('CreateProjectSecondStepButtonEnd')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default CreateProject