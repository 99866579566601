import {Route, Routes} from "react-router-dom";
import React from 'react';
import AuthForm from "../IndexAuth/AuthForm";
import MainPage from "../MainPage/MainPage";
import Body from "../MainPage/Body/Body";
import ProjectPage from "../ProjectPage/ProjectPage";
import CreateProject from "../ProjectPage/Admin/CreateProject";
import ChangeProject from "../ProjectPage/Admin/ChangeProject";
import RoleContainer from "../MainPage/Role/RoleContainer";
import SearchUserContainer from "../MainPage/SearchUser/SearchUserContainer";
import InviteStructure from "../Structure/InviteStructure";
import GraphicalStructure from "../Structure/GraphicalStructure";
import Partnership from "../MainPage/Partnership/Partnership";
import Events from "../Events/Events";
import Transfer from "../Transfers/Transfers";
import Verification from "../verification/verification";
import Agreement from "../Agreement/agreement";
import InvoiceAgreement from "../Agreement/Pages/invoice";
import WalletPage from "../Wallet/WalletPage";

function IndexRouter() {
    return (
        <>
            <Routes>
                <Route path="/" element={<AuthForm/>}/>
                <Route path="/mainpage" element={<MainPage/>}>
                    <Route path="/mainpage" element={<Body/>}/>
                    <Route path="/mainpage/role" element={<RoleContainer/>}/>
                    <Route path="/mainpage/searchusers" element={<SearchUserContainer/>}/>
                    <Route path="wallet" element={<WalletPage/>}/>
                    <Route path="project" element={<ProjectPage/>}/>
                    <Route path="create-project" element={<CreateProject/>}/>
                    <Route path="change-project" element={<ChangeProject/>}/>
                    <Route path="invite-structure" element={<InviteStructure/>}/>
                    <Route path="graphical-structure" element={<GraphicalStructure/>}/>
                    <Route path="partnership" element={<Partnership/>}/>
                    <Route path="events" element={<Events/>}/>
                    <Route path="transfer" element={<Transfer/>}/>
                    <Route path="verification" element={<Verification/>}/>
                    <Route path="agreement" element={<Agreement/>}/>
                    <Route path="invoice" element={<InvoiceAgreement/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default IndexRouter;