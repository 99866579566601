import React from "react";
import '../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function ProjectName () {
const { t, i18n } = useTranslation();

    return(
        <div className="nameWrapper" style={{marginBottom:'15px'}}>
        <span className="nameText"> 
           {t('MainPageProjectTitle')}
        </span>
        </div>
    )
}

export default ProjectName