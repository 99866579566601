import React from "react";
import '../../Style/Body.scss'
import {useSelector} from "react-redux";
import ProjectBody from "./ProjectBody";

function Body () {
    useSelector((state) => state.OpenNavBar.dataNav)

    return(
        <>
        <div className="mainPageBody">
            <ProjectBody/>
        </div>
        </>
    )
}

export default Body