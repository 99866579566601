import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {Outlet} from "react-router-dom";


function MainPageContainer({theme, isSmallScreen}) {

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: '#DCDCDC'
    }

    return (
        <div style={style} className="mainPageContainer">
            <Header theme={theme} isSmallScreen={isSmallScreen}/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default MainPageContainer