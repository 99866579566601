import React, {useEffect, useState} from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import ChangeProjectName from "./ChangeProjectName";
import {useDropzone} from "react-dropzone";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function ChangeProject() {
    const theme = useTheme();
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [primaryImage, setPrimaryImage] = useState(null);
    const [primaryImageName, setPrimaryImageName] = useState('');

    const [additionalImage, setAdditionalImage] = useState(null);
    const [additionalImageName, setAdditionalImageName] = useState('');

    const [document, setDocument] = useState(null);
    const [documentName, setDocumentName] = useState('');

    const [isSecondStep, setIsSecondStep] = useState(false);

    const [result, setResult] = useState();

    const location = useLocation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (location.state && location.state.project) {
            console.log('project', location.state.project);
            setResult(location.state.project);
        }
    }, [location]);


    const onDropPrimaryImage = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setPrimaryImage(file);
            setPrimaryImageName(file.name);
        }
    };

    const onDropAdditionalImage = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setAdditionalImage(file);
            setAdditionalImageName(file.name);
        }
    };

    const onDropDocument = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setDocument(file);
            setDocumentName(file.name);
        }
    };

    const {getRootProps: getRootPrimaryImageProps, getInputProps: getInputPrimaryImageProps} =
        useDropzone({onDrop: onDropPrimaryImage, accept: "image/*"});

    const {getRootProps: getRootAdditionalImageProps, getInputProps: getInputAdditionalImageProps} =
        useDropzone({onDrop: onDropAdditionalImage, accept: "image/*"});

    const {getRootProps: getRootDocumentProps, getInputProps: getInputDocumentProps} =
        useDropzone({onDrop: onDropDocument, accept: ".pdf,.doc,.docx,.txt"});

    const handleAddFile = async (file, type) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT) + `/file`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectId', result.id);
        formData.append('type', type);
        console.log("formData", file)

        try {
            await axios.put(apiUrl, formData, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
            });
            alert(`${t('CreateProjectSecondStepTitle')}`)
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const handleFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        setResult((prevState) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    const handleDateFieldChange = (field, newValue) => {
        const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : null;
        console.log(formattedDate);
        setResult((prevState) => ({
            ...prevState,
            [field]: formattedDate,
        }));
    };

    const handleNumericFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        // Filter input to allow only digits and dot
        const floatRegex = /^[0-9]*\.?[0-9]*$/;
        if (newValue === '' || floatRegex.test(newValue)) {
            setResult((prevState) => ({...prevState, [field]: newValue}));
        }
    }

    const handleMapClick = (e) => {
        const coords = e.get('coords');
        console.log('coords', coords);
        setResult((prevState) => ({
            ...prevState,
            lat: coords[0],
            lng: coords[1],
        }));
    };

    const handleChangeProject = async (e) => {
        e.preventDefault();
        console.log('result', result);
        const body = {
            title: result.title,
            description: result.description,
            beginDate: result.beginDate,
            endDate: result.endDate,
            poolSum: result.poolSum,
            lng: result.lng,
            lat: result.lat
        };
        console.log('body', body);
        const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT) + `/${result.id}`;
        try {
            await axios.put(apiUrl, body, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            }).then((response) => {
                setIsSecondStep(true);
                console.log(response.data.id);
            });
        } catch (error) {
            alert(error.response.data.message);
            console.error(error);
        }
    };

    const handleProjectNavigation = () => {
        // TODO: change to future project page
        navigate('/mainpage');
    }


    return (
        <>
            {result &&
                <>
                    {!isSecondStep &&
                        <>
                            <ChangeProjectName step={t('CreateProjectTitleStepsFirst')}/>
                            <div className="projectWrapper">
                                <div className="projectContainer" style={{padding: '0px'}}>
                                    <div className="projectPreviewWrapper" style={{padding: '0px'}}>
                                        <div className="projectContainer"
                                             style={{padding: '0px', alignItems: 'flex-start'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <div className="contactsWrapper" style={{flex: '0 0 35%'}}>
                                                    <div className="nameWrapper" style={{marginBottom: "10px"}}>
                                                <span style={{marginLeft: '0px'}} className="nameText">
                                                    {t('CreateProjectInfo')}
                                                </span>
                                                    </div>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }} value='1'>
                                                        <TextField sx={{marginTop: '10px', width: '100%'}}
                                                                   id="outlined-basic"
                                                                   label={t('CreateProjectNameProject')}
                                                                   variant="outlined" value={result.title}
                                                                   onChange={handleFieldChange('title')}/>
                                                        <TextField
                                                            sx={{marginTop: '10px', width: '100%'}}
                                                            id="outlined-basic"
                                                            label={t('CreateProjectDescriptionProject')}
                                                            variant="outlined"
                                                            value={result.description}
                                                            onChange={handleFieldChange('description')}
                                                            multiline
                                                            rows={5}
                                                        />
                                                        {/* <TextField
                                                            sx={{marginTop: '10px', width: '100%'}}
                                                            id="outlined-basic"
                                                            label={t('CreateProjectMinSum')}
                                                            variant="outlined"
                                                            value={result.minPrice}
                                                            onChange={handleNumericFieldChange('minPrice')}
                                                            inputProps={{
                                                                inputMode: 'decimal',
                                                                pattern: '[0-9]*[.]?[0-9]*'
                                                            }}
                                                        />
                                                        <TextField
                                                            sx={{marginTop: '10px', width: '100%'}}
                                                            id="outlined-basic"
                                                            label={t('CreateProjectMinSumSecond1')}
                                                            variant="outlined"
                                                            value={result.repeatPrice}
                                                            onChange={handleNumericFieldChange('repeatPrice')}
                                                            inputProps={{
                                                                inputMode: 'decimal',
                                                                pattern: '[0-9]*[.]?[0-9]*'
                                                            }}
                                                        />
                                                        <TextField
                                                            sx={{marginTop: "10px", width: "100%"}}
                                                            id="outlined-select-currency"
                                                            select
                                                            label={t('CreateProjectWaysMain')}
                                                            value={result.contributionType}
                                                            onChange={handleFieldChange("contributionType")}
                                                        >
                                                            {contributionTypes.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField> */}
                                                        <DateTimePicker
                                                            views={["day", "month", "year"]}
                                                            sx={{marginTop: "10px", width: "100%"}}
                                                            label={t('CreateProjectDate')}
                                                            value={
                                                                result.endDate
                                                                    ? dayjs(result.endDate)
                                                                    : null
                                                            }
                                                            onChange={(newValue) =>
                                                                handleDateFieldChange("endDate", newValue)
                                                            }
                                                        />
                                                        <TextField
                                                            sx={{marginTop: '10px', width: '100%'}}
                                                            id="outlined-basic"
                                                            label={t('CreateProjectSumPull')}
                                                            variant="outlined"
                                                            value={result.poolSum}
                                                            onChange={handleNumericFieldChange('poolSum')}
                                                            inputProps={{
                                                                inputMode: 'decimal',
                                                                pattern: '[0-9]*[.]?[0-9]*'
                                                            }}
                                                        />
                                                    </Box>
                                                </div>
                                                <div className="contactsWrapper" style={{flex: '0 0 60%'}}>
                                                    <div className="nameWrapper" style={{marginBottom: "20px"}}>
                                                <span style={{marginLeft: '0px'}} className="nameText">
                                                    {t('CreateProjectLocation')}
                                                </span>
                                                    </div>
                                                    <YMaps>
                                                        <div className="admin-project-map">
                                                            <Map
                                                                onClick={handleMapClick}
                                                                defaultState={{center: [result.lat, result.lng], zoom: 5}}
                                                                style={{width: '100%', height: '100%'}}
                                                            >
                                                                {result.lat && result.lng && (
                                                                    <Placemark geometry={[result.lat, result.lng]}/>
                                                                )}
                                                            </Map>
                                                        </div>
                                                    </YMaps>
                                                </div>
                                            </LocalizationProvider>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <button className="yellowButton buttonBoldText" style=
                                                {{
                                                    width: '100%',
                                                    backgroundColor: 'rgba(49,150,250,0.85)',
                                                    fontSize: '22px'
                                                }}
                                                    onClick={handleChangeProject}>
                                                {t('CreateProjectMainButtonUpdate')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {isSecondStep &&
                        <>
                            <ChangeProjectName step={t('CreateProjectTitleStepsSecond')}/>
                            <div className="projectWrapper">
                                <div className="projectContainer" style={{padding: '0px'}}>
                                    <div className="projectPreviewWrapper" style={{padding: '0px'}}>
                                        <div className="projectContainer"
                                             style={{padding: '0px', alignItems: 'flex-start'}}>
                                            <div className="contactsWrapper" style={{flex: '0 0 100%'}}>
                                                <div className="nameWrapper" style={{marginBottom: "10px"}}>
                                            <span style={{marginLeft: '0px'}} className="nameText">
                                                {t('CreateProjectSecondStepImg')}
                                            </span>
                                                </div>

                                                <div style={{marginBottom: '20px'}}>
                                                    <label
                                                        style={{
                                                            display: 'block',
                                                            margin: '30px 0 10px 0',
                                                            fontSize: '20px'
                                                        }}>
                                                        {t('CreateProjectSecondStepMainImg')}
                                                        : <label style={{
                                                        color: 'rgba(49,150,250,0.85)',
                                                        fontSize: '20px'
                                                    }}>{primaryImageName}</label>
                                                    </label>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        margin: '20px 0'
                                                    }}>
                                                        <div {...getRootPrimaryImageProps({className: 'yellowButton buttonBoldText'})}
                                                             style={{
                                                                 textAlign: 'center',
                                                                 lineHeight: '40px',
                                                                 minWidth: '100px',
                                                                 backgroundColor: '#ababab'
                                                             }}>
                                                            <input {...getInputPrimaryImageProps()}
                                                                   style={{display: 'none'}}/>
                                                            {t('CreateProjectSecondStepButtonSelect')}
                                                        </div>
                                                        <button className="yellowButton buttonBoldText"
                                                                style={{
                                                                    minWidth: '100px',
                                                                }}
                                                                onClick={() => handleAddFile(primaryImage, 'PRIMARY_IMAGE')}>
                                                            {t('CreateProjectSecondStepButtonInstall')}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div style={{marginBottom: '20px'}}>
                                                    <label
                                                        style={{
                                                            display: 'block',
                                                            margin: '30px 0 10px 0',
                                                            fontSize: '20px'
                                                        }}>
                                                        {t('CreateProjectSecondStepSecondImg')}
                                                        : <label style={{
                                                        color: 'rgba(49,150,250,0.85)',
                                                        fontSize: '20px'
                                                    }}>{additionalImageName}</label>
                                                    </label>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        margin: '20px 0'
                                                    }}>
                                                        <div {...getRootAdditionalImageProps({className: 'yellowButton buttonBoldText'})}
                                                             style={{
                                                                 textAlign: 'center',
                                                                 lineHeight: '40px',
                                                                 minWidth: '100px',
                                                                 backgroundColor: '#ababab'
                                                             }}>
                                                            <input {...getInputAdditionalImageProps()}
                                                                   style={{display: 'none'}}/>
                                                                    {t('CreateProjectSecondStepButtonSelect')}
                                                        </div>
                                                        <button className="yellowButton buttonBoldText"
                                                                style={{
                                                                    minWidth: '100px',
                                                                }}
                                                                onClick={() => handleAddFile(additionalImage, 'ADDITIONAL_IMAGE')}>
                                                                    {t('CreateProjectSecondStepButtonAdd')}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div style={{marginBottom: '20px'}}>
                                                    <label
                                                        style={{
                                                            display: 'block',
                                                            margin: '30px 0 10px 0',
                                                            fontSize: '20px'
                                                        }}>
                                                        {t('CreateProjectSecondStepDocProject')}
                                                        : <label style={{
                                                        color: 'rgba(49,150,250,0.85)',
                                                        fontSize: '20px'
                                                    }}>{documentName}</label>
                                                    </label>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        margin: '20px 0'
                                                    }}>
                                                        <div {...getRootDocumentProps({className: 'yellowButton buttonBoldText'})}
                                                             style={{
                                                                 textAlign: 'center',
                                                                 lineHeight: '40px',
                                                                 minWidth: '100px',
                                                                 backgroundColor: '#ababab'
                                                             }}>
                                                            <input {...getInputDocumentProps()}
                                                                   style={{display: 'none'}}/>
                                                                    {t('CreateProjectSecondStepButtonSelect')}
                                                        </div>
                                                        <button className="yellowButton buttonBoldText"
                                                                style={{
                                                                    minWidth: '100px',
                                                                }}
                                                                onClick={() => handleAddFile(document, 'DOCUMENT')}>
                                                             {t('CreateProjectSecondStepButtonInstall')}
                                                        </button>
                                                    </div>
                                                    <div style={{display: 'flex'}}>
                                                        <button className="yellowButton buttonBoldText" style=
                                                            {{
                                                                fontSize: '20px',
                                                                margin: '30px 0 0 0', // Using viewport height for margin
                                                                width: '100%', // Making the button width responsive
                                                                height: 'auto', // Ensuring height adjusts to content
                                                                backgroundColor: 'rgba(49, 150, 250, 0.85)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: '10px 20px', // Adjust padding as needed for button content
                                                            }}
                                                                onClick={handleProjectNavigation}>
                                                            {t('CreateProjectSecondStepButtonEnd')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default ChangeProject