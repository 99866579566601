import React from "react";
import Body from "./Body/Body";

function ProjectPageContainer ({theme, isSmallScreen}) {

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: '#DCDCDC'
    }

    return(
        <div style={style} className="mainPageContainer">
            <Body/>
        </div>
    )
}

export default ProjectPageContainer