import React from "react";
import '../../Style/Role/NameCabinet.scss'
import { useTranslation } from 'react-i18next';


function TableRoleTitle () {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="nameWrapper">
        <span className="nameText">

        {t('RoleTitle')}
        </span>
        </div>
        </div>
    )
}

export default TableRoleTitle