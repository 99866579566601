import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
function RewardSystem() {
  const [reward, setReward] = useState([]);
  const [enReward, setEnReward] = useState([])
  const [ruReward, setRuReward] = useState([])
  const handleGetReward = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.REWARD);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setReward(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetReward();
  }, []);

  const [rows, setRows] = useState(reward);

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
    console.log(rows);
  };

  useEffect(() => {
    setRows(reward);
    console.log(rows);
  }, [reward]);

  const handleAddRow = async () => {
    const lastIndex = rows.length - 1;
    console.log(rows[lastIndex].id)
    const lastId = lastIndex >= 0 ? rows[lastIndex].id : 0;
    const newId = lastId + 1;

    const apiUrl = urlFromTemplate(ENDPOINTS.REWARD);
    const payload = {
      id: newId,
      ruReward: ruReward,
      enReward: enReward,
    }
    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      handleGetReward()
      handleClose()
    }
  }

  const handleChangeRuRow = (value) => {
    setRuReward(value)
  }

  const handleChangeEnRow = (value) => {
    setEnReward(value)
  }


  const handleDeleteReward = async (id) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.REWARD) + `/${id}`;
    try {
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      alert(error.response.data.message);
    } finally {
      handleGetReward()
    }
  } 

  const handleSaveChanges = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.REWARD);
    const payload = rows;
    try {
      const response = await axios.put(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleGetReward()
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column'
  };
  
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table>
          <tbody id="tableReward">
            <tr>
            {rows.map((row, index) => (
              <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <td>
              <input
                        className="inputStyle"
                        type="text"
                        id={index}
                        value={row.ruReward}
                        onChange={(e) =>
                          handleChange(index, "ruReward", e.target.value)
                        }
                        />
              </td>
              <td>
              <input
                      className="inputStyle"
                      type="text"
                      name=""
                      id={index}
                      value={row.enReward}
                      onChange={(e) =>
                        handleChange(index, "enReward", e.target.value)
                      }
                    />
              </td>
              <Button onClick={() => handleDeleteReward(row.id)}>
                      <RemoveIcon />
                    </Button>
              </div>
                    ))}
            </tr>
            
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleOpen}>Добавить строку</Button>
        <Button onClick={handleSaveChanges}>Сохранить изменения</Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style,  }}>
         <tr>
         <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <td style={{height:'60px'}}>
              <input
                        className="inputStyle"
                        type="text"
                        value={ruReward}
                        onChange={(e) =>
                          handleChangeRuRow(e.target.value)
                        }
                        />
              </td>
              <td style={{height:'60px'}}>
              <input
                      className="inputStyle"
                      type="text"
                      name=""
                      value={enReward}
                      onChange={(e) =>
                        handleChangeEnRow(e.target.value)
                      }
                    />
              </td>
           
              </div>
              
         </tr>
         <Button onClick={handleAddRow}>
                      Сохранить
                    </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RewardSystem;
