import React, { useEffect, useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import "../../Style/Role/Role.scss";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import urlFromTemplate from "../../configs/url";
function TableRole() {
  const [anchorElRole, setAnchorElRole] = useState(null);
  const [anchorElGift, setAnchorElGift] = useState(null);
  const [anchorElEducation, setAnchorElEducation] = useState(null);
  const [anchorElRoleChange, setAnchorElRoleChange] = useState(null);
  const [anchorElRewardChange, setAnchorElRewardChange] = useState(null)
  const [anchorElRoleAdd, setAnchorElRoleAdd] = useState(null)
  const [anchorElTrainingChange, setAnchorElTrainingChange] = useState(null)
  const openRole = Boolean(anchorElRole);
  const openGift = Boolean(anchorElGift);
  const openEducation = Boolean(anchorElEducation);
  const openRoleChange = Boolean(anchorElRoleChange);
  const openRewardChange = Boolean(anchorElRewardChange)
  const openRoleAddChange = Boolean(anchorElRoleAdd)
  const openTrainingChange = Boolean(anchorElTrainingChange)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedStatusRename, setSelectedStatusRename] = useState(null);
  const [selectedNestedIndex, setSelectedNestedIndex] = useState(null);
  const [status, setStatus] = useState([]);
  const [reward, setRewards] = useState([])
  const [training, setTraining] = useState([])
  const [structureRequirements, setStructureRequirements] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const handleClickRole = (event, rowId, rowIndex, nestedIndex) => {
    setAnchorElRole(event.currentTarget);
    setSelectedRowId(rowId);
    setSelectedRowIndex(rowIndex);
    setSelectedNestedIndex(nestedIndex);
    console.log(rows[rowIndex].structureRequirements)
  };
  const handleClickGift = (event, rowId, rowIndex) => {
    setAnchorElGift(event.currentTarget);
    setSelectedRowId(rowId);
    setSelectedRowIndex(rowIndex);
 

  };
  const handleClickEducation = (event, rowId, rowIndex) => {
    setAnchorElEducation(event.currentTarget);
    setSelectedRowId(rowId);
    setSelectedRowIndex(rowIndex);

  };
  const handleClickRoleChange = (event, rowIndex, nestedIndex) => {
    setAnchorElRoleChange(event.currentTarget);
    // setSelectedRowIndex(rowIndex);
    setSelectedStatusRename(rowIndex);
    setSelectedNestedIndex(nestedIndex);
  };
  const handleClose = () => {
    setAnchorElRole(null);
    setAnchorElGift(null);
    setAnchorElEducation(null);
    setAnchorElRoleChange(null);
    setAnchorElRewardChange(null)
    setSelectedRowIndex(null)
  };

  const handleClickRewardChange = (event, rowIndex, nestedIndex) => {
    setAnchorElRewardChange(event.currentTarget)
    setSelectedRowIndex(rowIndex);

  }

  const handleClickRoleAdd = (event, rowIndex, nestedIndex) => {
    console.log(rows[rowIndex].structureRequirements)
    const index = rows[rowIndex].structureRequirements.length 
    console.log(`INDEXCOUNT ${index}`)
    const updatedRows = [...rows];
    updatedRows[rowIndex].structureRequirements.push({statusId: 13, statusCount: 0})
    // const currentRequirement =
    // updatedRows[rowIndex].structureRequirements[index];
    // const updatedRequirement = {
    //   ...currentRequirement,
    //   statusId: 13,
    //   statusCount: 1,
    // };
    // const lastIndex = updatedRows[rowIndex].structureRequirements.length - 1;
    // console.log(`LASTINDEX ${lastIndex}`)
    // updatedRows[rowIndex].structureRequirements[rowIndex] =
    // updatedRequirement;
    setRows(updatedRows);
    console.log(updatedRows[rowIndex].structureRequirements)
  }

  const handleClickTrainingChange = (event, rowIndex, nestedIndex) => {
    setAnchorElRewardChange(event.currentTarget)
    setSelectedRowIndex(rowIndex);

  }

  
  const handleGetRole = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.STATUS);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setStatus(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetRole();
    handleGetReward()
    handleGetTraining()
  }, []);



  const handleGetReward = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.REWARD);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },

      });
      setRewards(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetTraining = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },

      });
      setTraining(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveChanges = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.STATUS);
    const payload = rows
    try {
      const response = await axios.put(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },

      });
    } catch (error) {
      console.error(error);
    } finally{
      handleGetRole()
    }
  }
 
  const [rows, setRows] = useState(status);
  const [rewardRows, setRewardRows] = useState(reward)
  const [trainingRows, setTrainingRows] = useState(training)

  useEffect(() => {
    setRows(status)
  },[status])

  useEffect(() => {
    setRewardRows(reward)
  },[reward])

  useEffect(() => {
    setTrainingRows(training)
  },[training])

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleNestedChange = (rowIndex, nestedIndex, field, value) => {
    const updatedRows = [...rows];
    updatedRows[selectedRowIndex].structureRequirements[rowIndex][field] = value
    setRows(updatedRows);
  };

  const handleChangeRoleActive = (index, field, value) => {
    const updatedRows = [...rows];
  };

  const handleMenuItemClick = (rowIndex, nestedIndex, selectedValue) => {
    const updatedRows = [...rows];
    // updatedRows[rowIndex].structureRequirements[selectedStatusRename] = selectedValue;
    const currentRequirement =
    updatedRows[rowIndex].structureRequirements[selectedStatusRename];
    const updatedRequirement = {
      ...currentRequirement,
      statusId: selectedValue,
    };
    updatedRows[rowIndex].structureRequirements[selectedStatusRename] =
    updatedRequirement;
    setRows(updatedRows);
    handleClose();
  };


  const handleMenuRewardClick = (rowIndex, nestedIndex, selectedValue) => {
    const updatedRows = [...rows];
    // updatedRows[rowIndex].structureRequirements[selectedStatusRename] = selectedValue;
    updatedRows[rowIndex].rewardIds.push(selectedValue)
    setRows(updatedRows);
    handleClose();
  };

  const handleMenuTrainingClick = (rowIndex, nestedIndex, selectedValue) => {
    const updatedRows = [...rows];
    // updatedRows[rowIndex].structureRequirements[selectedStatusRename] = selectedValue;
    updatedRows[rowIndex].trainingIds.push(selectedValue)
    setRows(updatedRows);
    handleClose();
  };

  const handleDeleteReward = (selectedValue, index, rewardIndex) => {
    const updatedRows = [...rows];
    updatedRows[index].rewardIds.splice(rewardIndex, 1)
    setRows(updatedRows)
    handleClose()
  }

  const handleDeleteRole = (selectedValue, index, rewardIndex) => {
    console.log(index)
    const updatedRows = [...rows];
    updatedRows[index].structureRequirements.splice(rewardIndex, 1)
    setRows(updatedRows)
    console.log(updatedRows)
    handleClose()
  }

  const handleDeleteTraining = (selectedValue, index, rewardIndex) => {
    const updatedRows = [...rows];
    updatedRows[index].trainingIds.splice(rewardIndex, 1)
    setRows(updatedRows)
    handleClose()
  }


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedRows = Array.from(rows);
    const [removed] = reorderedRows.splice(result.source.index, 1);
    reorderedRows.splice(result.destination.index, 0, removed);

    // Update ordinal values
    reorderedRows.forEach((row, index) => {
      row.ordinal = index;
    });

    setRows(reorderedRows);
  };

  // useEffect(() =>{
  // }, [rows])

  const filteredStatus = (rowId) => {
    return status.filter((item) => item.id === rowId);
  };

  const filteredRewards = (rowId) => {
    return rewardRows.filter((item) => item.id === rowId);
  };

  const filteredTraining = (rowId) => {
    return trainingRows.filter((item) => item.id === rowId);
  };

  useEffect(() => {
  }, [rewardRows]);

  useEffect(() => {
    console.log(status);
  }, [status]);
  return (
    <div>
      <table className="iksweb">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-rows">
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {rows.map((row, index) => (
                  <Draggable
                    key={row.ordinal}
                    draggableId={`row-${row.ordinal}`}
                    index={index}
                    isDragDisabled={row.ordinal === 0 || row.ordinal === 1}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>
                          {row.ordinal}
                        </td>
                        <td>
                          <input
                            className="inputStyle"
                            type="text"
                            value={row.ruStatus}
                            onChange={(e) =>
                              handleChange(index, "ruStatus", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputStyle"
                            type="text"
                            value={row.enStatus}
                            onChange={(e) =>
                              handleChange(index, "enStatus", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputStyle"
                            type="text"
                            value={row.requiredContribution}
                            onChange={(e) =>
                              handleChange(index, "requiredContribution", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <Button
                            id="fade-button"
                            aria-controls={openRole ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openRole ? "true" : undefined}
                            onClick={(e) =>
                              handleClickRole(e, row.id, index, null)
                            }
                          >
                            Role
                          </Button>
                          <Menu
                            id="fade-menu"
                            MenuListProps={{ "aria-labelledby": "fade-button" }}
                            anchorEl={anchorElRole}
                            open={openRole && selectedRowId === row.id}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                                 <Button
                                   id="fade-button"
                                   aria-controls={
                                    openRoleAddChange ? "fade-menu" : undefined
                                   }
                                   aria-haspopup="true"
                                   aria-expanded={
                                    openRoleAddChange ? "true" : undefined
                                   }
                                   onClick={(e) =>
                                    handleClickRoleAdd(e, index, row.id)
                                   }
                            >
                              <AddIcon/>
                            </Button>
                            {row.structureRequirements.map(
                              (item, index, nestedIndex) => (
                                <MenuItem style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                  <input
                                    style={{ width: "50px" }}
                                    type="text"
                                    value={item.statusCount}
                                    onChange={(e) =>
                                      handleNestedChange(
                                        index,
                                        nestedIndex,
                                        "statusCount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Button
                                    id="fade-button"
                                    aria-controls={
                                      openRoleChange ? "fade-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      openRoleChange ? "true" : undefined
                                    }
                                    onClick={(e) =>
                                      handleClickRoleChange(e, index, row.id)
                                    }
                                  >
                                    {filteredStatus(item.statusId).map(
                                      (statusItem, statusIndex) => (
                                        <MenuItem key={statusIndex}>
                                          {statusItem.ruStatus}
                                        </MenuItem>
                                      )
                                    )}
                                  </Button>
                                  <Button
                                 onClick={(e) => handleDeleteRole(item.id, selectedRowIndex, index)}
                                  
                                  >
                                    <RemoveIcon/>
                                  </Button>
                                </MenuItem>
                              )
                            )}
                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorElRoleChange}
                              open={openRoleChange}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              {rows.map((innerRow, idx) => (
                                <MenuItem
                                  key={idx}
                                  onClick={() =>
                                    handleMenuItemClick(
                                      selectedRowIndex,
                                      selectedNestedIndex,
                                      innerRow.id
                                    )
                                  }
                                >
                                  {innerRow.ruStatus}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Menu>
                        </td>
                        <td>
                          <input
                            className="inputStyle"
                            type="text"
                            value={row.consultantBonus}
                            onChange={(e) =>
                            handleChange(index, "consultantBonus", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="inputStyle"
                            type="text"
                            value={row.monthBonus}
                            onChange={(e) =>
                            handleChange(index, "monthBonus", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <Button
                            id="fade-button"
                            aria-controls={openGift ? "fade-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openGift ? "true" : undefined}
                            onClick={(e) => handleClickGift(e, row.id, index)}
                          >
                            Reward
                          </Button>
                          <Menu
                            id="fade-menu"
                            MenuListProps={{ "aria-labelledby": "fade-button" }}
                            anchorEl={anchorElGift}
                            open={openGift && selectedRowId === row.id}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            <Button
                                   id="fade-button"
                                   aria-controls={
                                    openRewardChange ? "fade-menu" : undefined
                                   }
                                   aria-haspopup="true"
                                   aria-expanded={
                                    openRewardChange ? "true" : undefined
                                   }
                                   onClick={(e) =>
                                    handleClickRewardChange(e, index, row.id)
                                   }
                            >
                              <AddIcon/>
                            </Button>

                            {row.rewardIds.map((item, index) => (
                             <MenuItem>
                              {filteredRewards(item).map((rewardItem, rewardIndex) => (
                                <>
                                 <table className="iksweb">
                                 <tbody>
                               <tr>
                               <td style={{width:'50%'}}>
 
                               <MenuItem > {rewardItem.ruReward} </MenuItem>
                               </td>
                               <td>
                               <MenuItem > {rewardItem.enReward} </MenuItem>
                               </td>
                               </tr>
                               </tbody>
                               </table>
                                 <Button
                                 onClick={(e) => handleDeleteReward(rewardItem.id, selectedRowIndex, index)}
                                 >
                                 <RemoveIcon/>
                                 </Button>
                                 </>
                              ))}
                            

                             </MenuItem>
                            ))}
                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorElRewardChange}
                              open={openRewardChange}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                         
                         {rewardRows.map((item, idx) => (
                              <table className="iksweb">
                                <tbody>
                              <tr>
                              <td style={{width:'50%'}}>

                              <MenuItem key={idx}  onClick={() =>
                                    handleMenuRewardClick(
                                      selectedRowIndex,
                                      selectedNestedIndex,
                                      item.id
                                    )
                                  }> {item.ruReward} </MenuItem>
                              </td>
                              <td>
                              <MenuItem  onClick={() =>
                                    handleMenuRewardClick(
                                      selectedRowIndex,
                                      selectedNestedIndex,
                                      item.id
                                    )
                                  }> {item.enReward} </MenuItem>
                              </td>
                              </tr>
                              </tbody>
                              </table>
                            ))}
                            </Menu>
                     
                            {/* {rewardRows.map((item, idx) => (
                              <table className="iksweb">
                                <tbody>
                              <tr>
                              <td style={{width:'50%'}}>

                              <MenuItem key={idx}> {item.ruReward} </MenuItem>
                              </td>
                              <td>
                              <MenuItem > {item.enReward} </MenuItem>
                              </td>
                              </tr>
                              </tbody>
                              </table>
                            ))} */}
                                {/* {rewardRows.map((item, idx) => (
                              // <MenuItem key={idx}> {item.ruReward} </MenuItem>
                              <MenuItem key={idx}> {item.enReward} </MenuItem>
                            ))} */}
                          </Menu>
                        </td>
                        <td>
                          <Button
                            id="fade-button"
                            aria-controls={
                              openEducation ? "fade-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openEducation ? "true" : undefined}
                            onClick={(e) => handleClickEducation(e, row.id, index)}
                          >
                            Training
                          </Button>
                          <Menu
                            id="fade-menu"
                            MenuListProps={{ "aria-labelledby": "fade-button" }}
                            anchorEl={anchorElEducation}
                            open={openEducation && selectedRowId === row.id}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            {/* {row.systemEducation.map((item, idx) => (
                              <MenuItem key={idx}>{item.education}</MenuItem>
                            ))} */}
                                <Button
                                   id="fade-button"
                                   aria-controls={
                                    openRewardChange ? "fade-menu" : undefined
                                   }
                                   aria-haspopup="true"
                                   aria-expanded={
                                    openRewardChange ? "true" : undefined
                                   }
                                   onClick={(e) =>
                                    handleClickTrainingChange(e, index, row.id)
                                   }
                            >
                              <AddIcon/>
                            </Button>

                              {row.trainingIds.map((item, index) => (
                             <MenuItem>
                              {filteredTraining(item).map((rewardItem, rewardIndex) => (
                                <>
                                 <table className="iksweb">
                                 <tbody>
                               <tr>
                               <td style={{width:'50%'}}>
 
                               <MenuItem > {rewardItem.ruTraining} </MenuItem>
                               </td>
                               <td>
                               <MenuItem > {rewardItem.enTraining} </MenuItem>
                               </td>
                               </tr>
                               </tbody>
                               </table>
                                    <Button
                                    onClick={(e) => handleDeleteTraining(rewardItem.id, selectedRowIndex, index)}
                                    >
                                    <RemoveIcon/>
                                    </Button>
                                    </>
                              ))}
                             </MenuItem>
                            ))}
                             <Menu
                              id="fade-menu"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorElRewardChange}
                              open={openRewardChange}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                         
                         {trainingRows.map((item, idx) => (
                              <table className="iksweb">
                                <tbody>
                              <tr>
                              <td style={{width:'50%'}}>

                              <MenuItem key={idx}  onClick={() =>
                                    handleMenuTrainingClick(
                                      selectedRowIndex,
                                      selectedNestedIndex,
                                      item.id
                                    )
                                  }> {item.ruTraining} </MenuItem>
                              </td>
                              <td>
                              <MenuItem  onClick={() =>
                                    handleMenuTrainingClick(
                                      selectedRowIndex,
                                      selectedNestedIndex,
                                      item.id
                                    )
                                  }> {item.enTraining} </MenuItem>
                              </td>
                              </tr>
                              </tbody>
                              </table>
                            ))}
                            </Menu>
                          </Menu>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <Button onClick={handleSaveChanges}>
        Сохранить изменения
      </Button>
    </div>
  );
}

export default TableRole;
