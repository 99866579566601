import React, {useEffect, useState} from "react";
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import axios from "axios";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

function SystemWalletsTable() {

    const [currencies, setCurrencies] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [updatedWallets, setUpdatedWallets] = useState(new Map());
    const {t} = useTranslation();

    const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    const retrieveAllowedCurrencies = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SYSTEM_WALLET) + '/currencies';
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setCurrencies(response.data.currencies);
        } catch (error) {
            console.error(error);
        }
    };

    const retrieveSystemWallets = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SYSTEM_WALLET);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setWallets(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        retrieveAllowedCurrencies();
        retrieveSystemWallets();
    }, []);

    const handleSaveChanges = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SYSTEM_WALLET);
        const body = Array.from(updatedWallets, ([id, number]) => ({ id, number }));
        try {
            const response = await axios.put(apiUrl, body, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
        } catch (error) {
            console.error(error);
        } finally {
            retrieveSystemWallets()
        }
    };

    const handleChange = (dayOfWeek, currency, value) => {
        const matchingWallet = wallets.find((wallet) => wallet.dayOfWeek === dayOfWeek && wallet.currency === currency);
        if (!matchingWallet) {
            console.error('no matching wallet found');
        } else {
            setWallets(wallets.map((wallet) => {
                if (wallet.dayOfWeek === dayOfWeek && wallet.currency === currency) {
                    // Update the wallet number for the matching wallet
                    return { ...wallet, number: value };
                }
                return wallet;
            }));

            setUpdatedWallets(prevUpdatedWallets => {
                const updatedMap = new Map(prevUpdatedWallets);
                updatedMap.set(matchingWallet.id, value);
                return updatedMap;
            });
        }
    }

    const getWalletNumberByDayOfWeekAndCurrency = (dayOfWeek, currency) => {
        const matchingWallet = wallets.find((wallet) => wallet.dayOfWeek === dayOfWeek && wallet.currency === currency);
        return matchingWallet ? matchingWallet.number : 'SERVER ERROR';
    };

    return (<>{currencies && wallets && <>
            <div style={{display: "flex", justifyContent: "center"}}>
                <table>
                    <thead>
                    <tr>
                        <th>{t('DayOfWeek')}</th>
                        {currencies.map((currency, index) => (
                            <th key={index}>{currency}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody id="tableReward">
                    {daysOfWeek.map((dayOfWeek, dayIndex) => (
                        <tr key={dayIndex}>
                            <td style={{width: '200px'}}>
                                <input
                                    style={{minWidth: '200px'}}
                                    className="inputStyle"
                                    disabled
                                    type="text"
                                    value={t(dayOfWeek)}
                                />
                            </td>
                            {currencies.map((currency, currencyIndex) => (
                                <td key={currencyIndex} style={{width: '200px'}}> {/* Adjust width here */}
                                    <input
                                        style={{minWidth: '200px'}}
                                        className="inputStyle"
                                        type="text"
                                        id={currencyIndex}
                                        value={getWalletNumberByDayOfWeekAndCurrency(dayOfWeek, currency)}
                                        onChange={(e) =>
                                            handleChange(dayOfWeek, currency, e.target.value)
                                        }
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button onClick={handleSaveChanges}>Сохранить изменения</Button>
            </div>
        </>
        }
        </>
    );
}

export default SystemWalletsTable