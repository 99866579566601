import React, {useState} from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../Style/Auth/AuthBody.scss";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {ENDPOINTS} from "../configs/endpoints";
import urlFromTemplate from "../configs/url";
import {useDispatch} from "react-redux";
import {setCabinet} from "../Store/reducerCabinet";

function RegistrationBody() {
    const [valueInputLogin, setValueInputLogin] = useState("");
    const [valueInputPassword, setValueInputPassword] = useState("");
    const [forgotPass, setForgotPass] = useState(false);
    const [errorInput, setErrorInput] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setSiteSettingsInLocalStorage = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json", "Access-Control-Allow-Credentials": "true",
                },
            });
            localStorage.setItem("site_settings", JSON.stringify(response.data));
        } catch (error) {
            console.error(error);
        }
    };



    const buttonAuth = async () => {
        if (valueInputLogin && valueInputPassword) {
            const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/sign/in`;
            try {
                await axios
                    .post(apiUrl, {
                        login: valueInputLogin,
                        password: valueInputPassword,
                        role: 'ADMIN'
                    }, {
                        withCredentials: true, headers: {
                            Accept: "application/json", "Access-Control-Allow-Credentials": "true",
                        },
                    })
                    .then((response) => {
                        dispatch(setCabinet(response.data));
                        setSiteSettingsInLocalStorage();
                        navigate("/mainpage");
                    });
            } catch (error) {
                console.error(error);
                alert(error.response.data.message);
                setErrorInput(true);
            }
        }
    };

    const handleChangeLogin = (event) => {
        setValueInputLogin(event.target.value);
    };

    const handleChangePassword = (event) => {
        setValueInputPassword(event.target.value);
    };

    const styleTabs = {
        width: "90%",
        maxWidth: "600px", // height: "600px",
        margin: "0 auto",
        marginTop: "10%",
        background: "white",
        borderRadius: "24px",
        border: "1px solid black",
        marginBottom: "100px",
    };

    const styleButton = {
        background: "rgb(250, 176, 49)", width: "50%", margin: "0 auto", padding: "10px", borderRadius: "0.875rem",
    };

    const handleForgotPass = () => {
        setForgotPass(!forgotPass);
    };
    return (<Box style={styleTabs}>
        {!forgotPass && (<TabContext value={"1"}>
            <TabPanel
                id="authReg"
                value="1"
                style={{
                    padding: "0",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "0 auto",
                    height: "500px",
                }}
                className="important-margin-bottom"
            >
                <div style={{fontSize: "2em", marginBottom: "20px ", display: "flex", justifyContent: "center"}}>
                    Авторизация
                </div>
                <Box sx={{
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
                     value="1">
                    <TextField
                        autoComplete="off"
                        error={errorInput || !valueInputLogin}
                        value={valueInputLogin}
                        onChange={handleChangeLogin}
                        sx={{width: "70%"}}
                        id="outlined-basic"
                        label="Введите логин"
                        variant="outlined"
                    />
                    <TextField
                        autoComplete="off"
                        error={errorInput || !valueInputPassword}
                        type="password"
                        value={valueInputPassword}
                        onChange={handleChangePassword}
                        sx={{width: "70%"}}
                        id="outlined-basic"
                        label="Введите пароль"
                        variant="outlined"
                    />
                </Box>
                <Button
                    style={styleButton}
                    onClick={buttonAuth}
                    variant="contained"
                >
                    Войти
                </Button>
                <div className="wrapperButtonForgotPassword">
                  <span>Забыли пароль?{" "}
                      <a style={{marginLeft: "5px"}} className="forgotPass" onClick={() => handleForgotPass()}>
                        Восстановить
                      </a>
                  </span>
                </div>
            </TabPanel>
        </TabContext>)}
        {forgotPass && (<div className="forgotPassWrapper">
            <div className="forgotPassContainer">
                <div className="forgonPassDesc">
                    <p>
                        <b>Восстановление пароля</b>
                    </p>
                    <p>
                        Укажите свой логин или email и получите ссылку для
                        восстановления
                    </p>
                </div>
                <div className="forgotPassForm">
                    <div className="inputForgotPass">
                        <TextField
                            autoComplete="off"
                            error={!valueInputLogin}
                            value={valueInputLogin}
                            onChange={handleChangeLogin}
                            sx={{width: "100%"}}
                            id="outlined-basic"
                            label="Ваш логин или email"
                            variant="outlined"
                        />
                    </div>
                    <div className="buttonForgotPassWrapper">
                        <div
                            onClick={() => handleForgotPass()}
                            className="exitButtonPass"
                        >
                            Отмена
                        </div>
                        <div className="nextButtonPass">Далее</div>
                    </div>
                </div>
            </div>
        </div>)}
    </Box>);
}

export default RegistrationBody;
