import React, { useEffect, useState } from "react";
import { ReactComponent as StatisticsManPlus } from "../images/statistics/statisticsManPlus.svg";
import { ReactComponent as StatisticsMan } from "../images/statistics/statisticsMan.svg";
import { ReactComponent as Statistics } from "../images/statistics/statistics.svg";
import { ReactComponent as Statistics2 } from "../images/statistics/statistics2.svg";
import { ReactComponent as Structure } from "../images/statistics/structure.svg";
import { useTranslation } from 'react-i18next';
import '../Style/Partners/BonusPartner.scss'

function InviteCounts({counts}) {

    const { t, i18n } = useTranslation();

    return (
        <div className="wrapperBonusPartner" style={{padding: '20px', minHeight: '100px'}}>
            <div className="inviteCountsWrapper">
                <div className="block" style={{display: "flex", alignItem: "centr"}}>
                    <StatisticsManPlus style={{width: '25px', height: '25px', marginRight: '5px'}}/>
                    <div>
                        <span style={{color: 'gray', fontSize: '12px'}}>
                            {t('CabinetPartnerStructureBarNew')}
                        </span>
                        {counts && <div style={{fontWeight: 'bold'}}>{counts.newPartners}</div>}
                    </div>
                </div>
                <div className="block" style={{display: "flex", alignItem: "centr"}}>
                    <StatisticsMan style={{width: '25px', height: '25px', marginRight: '5px'}}/>
                    <div>
                        <span style={{color: 'gray', fontSize: '12px'}}>
                            {t('CabinetPartnerStructureBarPersonal')}
                        </span>
                        {counts && <div style={{fontWeight: 'bold'}}>{counts.newPartnersFirstLine}</div>}
                    </div>
                </div>
                <div className="block" style={{display: "flex", alignItem: "centr"}}>
                    <Structure style={{width: '25px', height: '25px', marginRight: '5px'}}/>
                    <div>
                        <span style={{color: 'gray', fontSize: '12px'}}>
                            {t('CabinetPartnerStructureBarAll')}
                        </span>
                        {counts && <div style={{fontWeight: 'bold'}}>{counts.allPartners}</div>}
                    </div>
                </div>
                <div className="block" style={{display: "flex", alignItem: "centr"}}>
                    <Statistics style={{width: '25px', height: '25px', marginRight: '5px'}}/>
                    <div>
                        <span style={{color: 'gray', fontSize: '12px'}}>
                            {t('CabinetPartnerStructureBarActive')}
                        </span>
                        {counts && <div style={{fontWeight: 'bold'}}>{counts.activePartnersFirstLine}</div>}
                    </div>
                </div>
                <div className="block" style={{display: "flex"}}>
                    <Statistics2 style={{width: '25px', height: '25px', marginRight: '5px'}}/>
                    <div>
                        <span style={{color: 'gray', fontSize: '12px'}}>
                            {t('CabinetPartnerStructureBarActiveSecond')}
                        </span>
                        {counts && <div style={{fontWeight: 'bold'}}>{counts.activePartners}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InviteCounts