import React, {useEffect, useState} from "react";
import ProjectName from "./NameProject";
import '../../Style/Project/Project.scss'
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {
    GaugeContainer,
    GaugeValueArc,
    GaugeReferenceArc,
    useGaugeState,
} from '@mui/x-charts/Gauge';
import {Button, useMediaQuery, useTheme} from "@mui/material";
import {fetchImageById} from "../../Fetches/fetchFileById";
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

function ProjectBody() {
    const [gaugeWidth, setGaugeWidth] = useState(120);
    const [images, setImages] = useState(new Map());
    const [allProject, setAllProject] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const [pagination, setPagination] = useState()
    const [page, setPage] = useState(1)
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     if (isSmallScreen) {
    //         setGaugeWidth(120);
    //     } else {
    //         setGaugeWidth(150);
    //     }
    // }, [isSmallScreen]);


    function GaugePointer() {
        const {valueAngle, outerRadius, cx, cy} = useGaugeState();

        if (valueAngle === null) {
            // No value to display
            return null;
        }

        const target = {
            x: cx + outerRadius * Math.sin(valueAngle),
            y: cy - outerRadius * Math.cos(valueAngle),
        };
        return (
            <g>
                <circle cx={cx} cy={cy} r={5} fill="red"/>
                <path
                    d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
                    stroke="red"
                    strokeWidth={3}
                />
            </g>
        );
    }

    const [projects, setProjects] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        getProject()
    }, []);

    useEffect(() => {
        const fetchAllImages = async () => {
            const imagesMap = new Map();

            for (const project of projects) {
                const urlId = project.projectFiles.primaryPhotoId;
                if (!urlId) {
                    imagesMap.set(project.id, null);
                } else {
                    imagesMap.set(project.id, await fetchImageById(urlId));
                }
            }
            setImages(imagesMap);
            console.log('imagesMap', imagesMap);
        };

        fetchAllImages();
    }, [projects]);

    const handleMoreInfo = (project) => {
        console.log(project);
        navigate('./project', {
            state: {
                project: project
            }
        });
    };

    const handleChangeProject = (project) => {
        navigate('./change-project', {
            state: {
                project: project
            }
        });
    };

    const getProject = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT)
        try {
            await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                params:{
                size:3,
                page: page - 1

                }
            }).then((response) => {
                setProjects(response.data.content);
                setPagination(response.data.totalPages)
                setAllProject(false)
            })
        } catch (error) {
            console.error(error)
        }
    }


 // пагинация
 const handleChangePage = (event, value) =>{
    setPage(value)
}

useEffect(() => {
getProject()
}, [page])

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                <ProjectName/>
                <div className="moreDetails activeElement" style={{margin: '20px 10px 0 0', width:'100px'}}>
                    <Link className="moreDetails" to={'./create-project'}>
                        {t('MainPageProjectButtonAdd')}
                    </Link>
                </div>
            </div>
            {projects.map((item) => (
                <div className="projectPreviewWrapper">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div className="nameWrapperProject" style={{flexGrow: 1}}>
                            {item.title}
                        </div>
                    
                        <div className="moreDetails activeElement" style={{margin: '10px 20px 0 0'}}
                             onClick={() => handleChangeProject(item)}>
                            <Link className="moreDetails">
                                {t('MainPageProjectButtonChange')}
                            </Link>
                        </div>
                        <div className="moreDetails activeElement" style={{marginTop: '10px'}} А
                             onClick={() => handleMoreInfo(item)}>
                            <Link className="moreDetails">
                                {t('MainPageProjectMoreDetails')}
                            </Link>
                        </div>
                
                    </div>
                    <div className="projectContainer">
                        {images.has(item.id) && images.get(item.id) ? (
                            <div className="projectImg" style={{backgroundImage: `url(${images.get(item.id)})`}}></div>
                        ) : (
                            <div className="projectImg placeholderImage"></div>
                        )}
                        <div className="projectInfoWrapper">
                            <div className="projectInfoFirst">
                                <div className="projectInfo">
                                    <div className="imgInfo">
                                    </div>
                                    <div className="infoTextWrapper">
                                        <div className="infoMoney">
                                            20%
                                        </div>
                                        <div className="infoText">
                                            {t('MainPageProjectProfitPerMonth')}
                                        </div>

                                    </div>
                                </div>
                                <div className="projectInfo">
                                    <div className="imgInfo">
                                    </div>
                                    <div className="infoTextWrapper">
                                        <div className="infoMoney">
                                            €{item.minPrice}
                                        </div>
                                        <div className="infoText" id="infoTextGreen">
                                            {t('MainPageProjectMinimumTransactionAmount')}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="projectInfoSecond">
                                <div className="projectInfo">
                                    <div className="imgInfo" id="calendarProject">
                                    </div>
                                    <div className="infoTextWrapper">
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramStart"
                              )}
                              : {item.beginDate}
                            </div>
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramEnd"
                              )}
                              : {item.endDate}
                            </div>
                          </div>
                                </div>
                                <div className="projectInfo">
                                    <div className="imgInfo"></div>
                                    <div className="infoTextWrapper">
                                        <div className="infoMoney">
                                            €{item.repeatPrice}
                                        </div>
                                        <div className="infoText" id="infoTextGreen">
                                            {t('MainPageProjectMinRepeatTransactionAmount')}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="projectPullWrapper">
                            <div className="projectPull">
                                <GaugeContainer
                                    width={gaugeWidth}
                                    height={120}
                                    startAngle={-110}
                                    endAngle={110}
                                    value={(item.income / item.poolSum) * 100}
                                >
                                    <GaugeReferenceArc/>
                                    <GaugeValueArc/>
                                    <GaugePointer/>
                                </GaugeContainer>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                maxWidth: '200px',
                                flexDirection: 'column'
                            }}>
                                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                    <div style={{fontSize: '13px'}}>€{item.income}</div>
                                    <div style={{fontSize: '13px'}}>€{item.poolSum}</div>
                                </div>
                                <div style={{margin: '5px auto'}}>{t('MainPageProjectPoolFilling')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
        <Stack spacing={2}>
      <Pagination style={{margin:'0 auto'}}  count={pagination} page={page} onChange={handleChangePage} color="primary" />
    </Stack> 
        </>
    )
}

export default ProjectBody