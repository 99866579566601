import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "../Style/Modal/SiteSettingsModal.scss"
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import axios from "axios";
import { useTranslation } from 'react-i18next';
const SiteSettingsModal = ({isOpen, onClose}) => {

    const [chosenCountry, setChosenCountry] = useState('');
    const [chosenLanguage, setChosenLanguage] = useState('');
    const [chosenCurrency, setChosenCurrency] = useState('');
    const [result, setResult] = useState({});
    const [errorLabel, setErrorLabel] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(result && result.timezone !== undefined && result.language !== undefined && result.currency !== undefined) {
            setChosenCountry(result.timezone);
            setChosenLanguage(result.language);
            setChosenCurrency(result.currency);
        }
        // console.log(Cookies.get('Access_Cookie'))
    }, [result]);

    useEffect(() => {
        const language = localStorage.getItem('site_settings')
       if (language){
        let myObject = JSON.parse(language);
        i18n.changeLanguage(myObject['language'])
       }
},[])

    useEffect(() => {
        if(isOpen) {
            handleSiteSettings()
        }
        // console.log(Cookies.get('Access_Cookie'))
    }, [isOpen]);

    const handleSiteSettings = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            console.log("response.data")
            console.log(response.data)
            setResult(response.data)
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        i18n.changeLanguage(chosenLanguage)
        e.preventDefault();
        const currentSiteSettings = {
            timezone: chosenCountry,
            language: chosenLanguage,
            currency: chosenCurrency
        };
        const currentSiteSettingsJSON = JSON.stringify(currentSiteSettings);
        localStorage.setItem('site_settings', currentSiteSettingsJSON);
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS);
        try {
            await axios.put(apiUrl, currentSiteSettings, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }).then((response) => {
                handleCloseModal(e);
            });
        } catch (error) {
            console.error(error);
            setErrorLabel(error.message);
        }
    };

    const handleCloseModal = (e) => {
        e.preventDefault();
        setErrorLabel('');
        setResult({});
        onClose();
    }

    const countryList = [
        {value: 'Belarus', label: 'Europe/Minsk',},

    ];

    const languageList = [
        {value: 'Русский', label: 'Русский'},
        {value: 'English', label: 'English'},
        {value: 'Turkish', label: 'Turkish'},
    ]

    const currencyList = [
        {value: 'USD', label: 'USD'},
        {value: 'Bitcoin', label: 'Bitcoin'}
    ]

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            {result && result.timezone !== undefined && result.language !== undefined && result.currency !== undefined &&
            <div className="modal-content">
                <label className="modalTitle"> {t('SiteSettingsModalTitle')} </label>
                <Box sx={{
                    maxWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }} value='1'>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLableCountry')}
                        value={chosenCountry} 
                        onChange={(event) => setChosenCountry(event.target.value)}
                    >
                        {countryList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLabelLanguage')}
                        value={chosenLanguage} 
                        onChange={(event) => setChosenLanguage(event.target.value)}
                    >
                        {languageList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLabelCurrancy')}
                        value={chosenCurrency} 
                        onChange={(event) => setChosenCurrency(event.target.value)}
                    >
                        {currencyList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {errorLabel && errorLabel !== '' &&
                        <p style={{ color: 'red', textAlign: 'center', fontSize: '17px'}}>{errorLabel}</p>
                    }
                    <div className="siteSettingsButtonContainer">
                        <button className="whiteButton buttonBoldText siteSettingsButton" style={{marginRight: '10px'}}
                                onClick={handleCloseModal}>
                            {t('SiteSettingsModalButtonClose')}
                        </button>
                        <button className="yellowButton buttonBoldText siteSettingsButton " 
                            id='siteSettingsButtonSave'
                            onClick={handleSubmit}>
                            {t('SiteSettingsModalButtonSave')}
                        </button>
                    </div>
                </Box>
            </div>}
        </div>
    );
};

export default SiteSettingsModal;