import React from "react";
import '../Style/Auth/Authorization.scss'
import RegistrationHeader from "./AuthHeader";
import RegistrationBody from "./AuthBody";


function AuthForm () {
    return (
        <div className="registrationWrapper">
            <RegistrationHeader/>
            <RegistrationBody/>
            {/* <RegistrationFooter/> */}
        </div>
    )
}

export default AuthForm