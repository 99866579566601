// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.iksweb {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  height: auto;
}

table.iksweb, table.iksweb td, table.iksweb th {
  border: 1px solid #595959;
}

table.iksweb td, table.iksweb th {
  padding: 3px;
  width: 30px;
  height: 35px;
}

table.iksweb th {
  background: #347c99;
  color: #fff;
  font-weight: normal;
}

.inputStyle {
  width: 100px !important;
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Style/Role/Role.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;AACD;;AACA;EACC,yBAAA;AAED;;AAAA;EACC,YAAA;EACA,WAAA;EACA,YAAA;AAGD;;AADA;EACC,mBAAA;EACA,WAAA;EACA,mBAAA;AAID;;AADA;EACI,uBAAA;EACA,YAAA;AAIJ","sourcesContent":["table.iksweb{\n\twidth: 100%;\n\tborder-collapse:collapse;\n\tborder-spacing:0;\n\theight: auto;\n}\ntable.iksweb,table.iksweb td, table.iksweb th {\n\tborder: 1px solid #595959;\n}\ntable.iksweb td,table.iksweb th {\n\tpadding: 3px;\n\twidth: 30px;\n\theight: 35px;\n}\ntable.iksweb th {\n\tbackground: #347c99; \n\tcolor: #fff; \n\tfont-weight: normal;\n}\n\n.inputStyle{\n    width: 100px !important;\n    height: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
