// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageBody {
  background-color: #DCDCDC;
}

.activeElement {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Style/Body.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAIA;EACI,eAAA;AADJ","sourcesContent":[".mainPageBody{\n    background-color: #DCDCDC;\n    // width: 100%;\n    // height: 10000px;\n}\n\n.activeElement{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
