import './App.css';
import Router from './Routes/IndexRouter';

function App() {
    return (
        <>
            <Router/>
        </>
    );
}

export default App;
