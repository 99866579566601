import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import ProjectPageContainer from "./ProjectPageContainer";

function ProjectPage() {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row'
    }

    return (
        <>
            <Box style={style}>
                <ProjectPageContainer theme={theme} isSmallScreen={isSmallScreen}/>
            </Box>
        </>
    )
}

export default ProjectPage