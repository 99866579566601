import React, { useRef, useState, useCallback, useEffect } from "react";
import "../../Style/SearchUsers/SearchUsers.scss";
import { TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
function SearchUsers() {
  const [searchUser, setSearchUser] = useState("");
  const searchInput = useRef(null);
  const [user, setUser] = useState([]);
  const { t, i18n } = useTranslation();

  const StyledTextField = styled(TextField)({
    width: "100%",
    margin: "10px",
    "& input::placeholder": {
      padding: "0px",
      marginBottom: "0px",
    },
    "& .MuiInputBase-input": {
      marginBottom: "0px",
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchUser(event.target.value);
  };

  const handleSearchUser = useCallback(async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/${searchUser}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
      setUser([response.data]);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [searchUser]);

  const handleSearchButtonClick = () => {
    handleSearchUser();
  };

  const handleActivate = async (id) => {
    const params = {};
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `-active/activate/${id}`;
    try {
      const response = await axios.post(apiUrl, params, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  // const handelGetUsers = async () => {
  //   const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/admin`
  //   try {
  //     const response = await axios.get
  //   }
  // }

  const getProject = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT);
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          // setProjects(response.data.content);
          // setPagination(response.data.totalPages)
          // setAllProject(false)
          console.log(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <>
         <div className="searchWrapper" style={{margin:'0 auto'}}>
        <div className="listSearchBlock" style={{ width: "100%" }}>
          <TextField
            autoComplete="off"
            placeholder="Поиск"
            variant="outlined"
            size="small"
            style={{ width: "100%", margin: "10px" }}
            value={searchUser}
            onChange={handleSearchChange}
            InputProps={{
              style: { height: "40px", borderRadius: "10px" },
              startAdornment: (
                <SearchIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleSearchButtonClick}
                />
              ),
            }}
          />
        </div>
      </div>
      <div className="searchUserWrapper">
        <div className="usersWrapper">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Photo</TableCell>
                  <TableCell align="right">id</TableCell>
                  <TableCell align="right">login</TableCell>
                  <TableCell align="right">Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.urlId}
                    </TableCell>
                    <TableCell align="right">{item.id}</TableCell>
                    <TableCell align="right">{item.login}</TableCell>
                    <TableCell align="right">{item.statusName}</TableCell>
                    {/* <TableCell align="right">{item.activeStatusName === 'Inactive' ? <button onClick={() => handleActivate(item.id)}>Активировать</button> : item.activeStatusName}</TableCell> */}
                    <TableCell align="right">
                      <button onClick={handleOpen}>
                        Активировать
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Button onClick={handleClose}>Close Child Modal</Button>
        </Box>
      </Modal>
    </>
  );
}

export default SearchUsers;
