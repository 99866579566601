// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageWrapperRegistration {
  width: 120px;
  height: 40px;
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.languageWrapperRegistration:hover {
  cursor: pointer;
  border: 1px solid white;
}

.flagLanguage {
  border-right: 1px solid black;
}

.languageName {
  font-size: 14px;
}

.ButtonAuthWrapper {
  display: flex;
  flex-wrap: nowrap;
}

.authWrapper {
  border-right: 1px solid black;
  padding-right: 10px;
  margin-right: 10px;
}

.auth {
  font-size: 20px;
  cursor: pointer;
}

.registrationButton {
  margin: 0 auto;
}

.registrtion {
  font-size: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Style/Auth/AuthHeader.scss"],"names":[],"mappings":"AAEA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;AADA;;AAGA;EACA,eAAA;EACA,uBAAA;AAAA;;AAGA;EACA,6BAAA;AAAA;;AAEA;EACA,eAAA;AACA;;AACA;EACA,aAAA;EACA,iBAAA;AAEA;;AAAA;EACA,6BAAA;EACA,mBAAA;EACA,kBAAA;AAGA;;AADA;EACI,eAAA;EACJ,eAAA;AAIA;;AAFA;EACA,cAAA;AAKA;;AAHA;EACI,eAAA;EACA,eAAA;AAMJ","sourcesContent":["\n\n.languageWrapperRegistration{\nwidth: 120px;\nheight: 40px;\nmargin-left: 20px;\nborder: 1px solid black;\nborder-radius: 10px;\ndisplay: flex;\nalign-items: center;\njustify-content: space-around;\n}\n.languageWrapperRegistration:hover{\ncursor: pointer;\nborder: 1px solid white;\n    }\n\n.flagLanguage{\nborder-right: 1px solid black;\n}\n.languageName{\nfont-size: 14px;\n}\n.ButtonAuthWrapper{\ndisplay: flex;\nflex-wrap: nowrap;\n}\n.authWrapper{\nborder-right: 1px solid black;\npadding-right: 10px;\nmargin-right: 10px;\n}\n.auth{\n    font-size: 20px;\ncursor: pointer;\n}\n.registrationButton{\nmargin: 0 auto;\n}\n.registrtion{\n    font-size: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
