import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
function TrainingSystem () {

    const [training, setTraining] = useState([])
    const [enTraining, setEnTraining] = useState([])
    const [ruTraining, setRuTraining] = useState([])
    const handleGetTraining = async () => {
      const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING);
      try {
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
  
        });
        setTraining(response.data)
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      handleGetTraining()
    },[])
    
      const [rows, setRows] = useState(training)
      
      const handleChange = (index, field ,value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value
        setRows(updatedRows)
    console.log(rows)

      }
    

      useEffect(() => {
        setRows(training)
        console.log(rows)
      },[training])
      
      const handleAddRow = async () => {
        const lastIndex = rows.length - 1;
        console.log(rows[lastIndex].id)
        const lastId = lastIndex >= 0 ? rows[lastIndex].id : 0;
        const newId = lastId + 1;

        const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING);
        const payload = {
          id: newId,
          ruTraining: ruTraining,
          enTraining: enTraining,
        }
        try {
          const response = await axios.post(apiUrl, payload, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
          })
        } catch (error) {
          console.error(error)
        } finally {
          handleGetTraining()
          handleClose()
        }
      }
    
      const handleChangeRuRow = (value) => {
        setRuTraining(value)
      }
    
      const handleChangeEnRow = (value) => {
        setEnTraining(value)
      }

      const handleDeleteTraining = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING) + `/${id}`;
        try {
          const response = await axios.delete(apiUrl, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
          });
        } catch (error) {
          alert(error.response.data.message);
        }
        finally {
          handleGetTraining()
         
        }
      } 

      const handleSaveChanges = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING);
        const payload = rows
        try {
          const response = await axios.put(apiUrl, payload, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
    
          });
        } catch (error) {
          console.error(error);
        } finally {
          handleGetTraining()
         
        }
      }

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column'
      };
      
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
          setOpen(true);
        };
        const handleClose = () => {
          setOpen(false);
        };
    
    
      return (
        <>
        <div style={{display:'flex', justifyContent:'center'}}>
          <table>
            <tbody>
            
                <tr>
            {rows.map((row, index) => (
              <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <td>
              <input className="inputStyle" type="text" id={index} value={row.ruTraining} onChange={(e) => handleChange(index, 'ruTraining', e.target.value)} />

              </td>
              <td>
              <input className="inputStyle" type="text" name="" id={index} value={row.enTraining} onChange={(e) => handleChange(index, 'enTraining', e.target.value)}  />
              </td>
              <Button onClick={() => handleDeleteTraining(row.id)}>
                      <RemoveIcon />
                    </Button>
              </div>
                    ))}
            </tr>
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleOpen}>Добавить строку</Button>
        <Button onClick={handleSaveChanges}>Сохранить изменения</Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style,  }}>
         <tr>
         <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <td style={{height:'60px'}}>
              <input
                        className="inputStyle"
                        type="text"
                        value={ruTraining}
                        onChange={(e) =>
                          handleChangeRuRow(e.target.value)
                        }
                        />
              </td>
              <td style={{height:'60px'}}>
              <input
                      className="inputStyle"
                      type="text"
                      name=""
                      value={enTraining}
                      onChange={(e) =>
                        handleChangeEnRow(e.target.value)
                      }
                    />
              </td>
           
              </div>
              
         </tr>
         <Button onClick={handleAddRow}>
                      Сохранить
                    </Button>
        </Box>
      </Modal>
        </>
      );
    }


export default TrainingSystem