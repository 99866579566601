import React, { useEffect, useState } from "react";
import '../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function InviteStructureTitle () {
    const { t, i18n } = useTranslation();


    return(
        <div className="nameWrapper" style={{width:'95.5%'}}>
        <span className="nameText">

        {t('CabinetPartnerStructureTitle')}

        </span>
        </div>
    )
}

export default InviteStructureTitle