import React from "react";
import InviteStructureTitle from "./InvteStructureTitle";
import InviteCounts from "./InviteCounts";
import InviteStructureTable from "./InviteStructureTable";
import '../Style/Partners/WrapperPartners.scss'
function InviteStructure () {
    return(
        <>
            <div className="wrapperPartners">
            <InviteStructureTitle/>
            <InviteStructureTable/>
            </div>
           
        </>
    )
}

export default InviteStructure