import React, { useEffect, useState } from "react";
import "../../Style/Footer/Footer.scss";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();


  const handleTerms = () =>{
    const languageColor = localStorage.getItem('site_settings')
    let myObject = JSON.parse(languageColor);
    let linkTerms = myObject['language']
    if(linkTerms == 'Русский'){
      navigate('/terms')
      
    } else if (linkTerms == 'English'){
      navigate('/terms-EN')
    }
  }

  const handlePolicy = () =>{
    const languageColor = localStorage.getItem('site_settings')
    let myObject = JSON.parse(languageColor);
    let linkTerms = myObject['language']
    if(linkTerms == 'Русский'){
      navigate('/privacy-policy/')
      
    } else if (linkTerms == 'English'){
      navigate('/privacy-policy-EN/')
    }
  }


  return (
    <>
      <div className="footerMainPageWrapper">
        <div className="footerLinksWrapper">
          <div className="footerLegalInformation">
            <div className="footerLegaInfoText">{t('FooterLegalInformation')}</div>
            <div className="policyFooterWrapper">
              <a className="policyFooterLinks" onClick={handlePolicy}>
              {t('FooterPrivacyPolicy')}
              </a>
              <a className="policyFooterLinks" onClick={handleTerms}>
              {t('FooterTermsOfUse')}
              </a>
            </div>
          </div>
          <div className="footerLegalInformation" id="footerLegalInformation">
            <div className="footerLegaInfoText">{t('FooterSupport')}</div>
            <div className="policyFooterWrapper">
              <div className="policyFooterLinksWrapper">
                <EmailIcon style={{marginRight:'10px', color:'#DAA520'}} />
                <a className="policyFooterLinks" href="">
                  support@test.test
                </a>
              </div>
              <div className="policyFooterLinksWrapper">
                <TelegramIcon color="primary" style={{marginRight:'10px'}} />
                <a className="policyFooterLinks" href="">
                  @support_bot
                </a>
              </div>
            </div>
          </div>
          <div className="footerLegalInformation" id="footerLegalInformation">
            <div className="footerLegaInfoText">{t('FooterSocial')}</div>
            <div className="policyFooterWrapper">
              <div className="policyFooterLinksWrapper">
                <TelegramIcon color="primary" style={{marginRight:'10px'}} />
                <a className="policyFooterLinks" href="">
                {t('FooterSocialTelegramNews')}
                </a>
              </div>
              <div className="policyFooterLinksWrapper">
                <TelegramIcon color="primary" style={{marginRight:'10px'}} />
                <a className="policyFooterLinks" href="">
                {t('FooterSocialTelegramNav')}
                </a>
              </div>
              <div className="policyFooterLinksWrapper">
                <YouTubeIcon style={{marginRight:'10px', color:'red'}}/>
                <a className="policyFooterLinks" href="">
                {t('FooterSocialYouTube')}
                </a>
              </div>
              <div className="policyFooterLinksWrapper">
                <InstagramIcon style={{marginRight:'10px', color:'#FF1493'}}/>
                <a className="policyFooterLinks" href="">
                {t('FooterSocialInstagram')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footerCopyWrapper">
            <div className="footerCopy">Copyright 2024 Diamond.</div>
            <div>All rights reserved</div>
        </div>
      </div>
    </>
  );
}

export default Footer;
