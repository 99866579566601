import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer.js'; 
import { logger } from './logger.js'; 
import OpenNavBar from './reducerNavBar.js'
import cabinetData from './reducerCabinet.js'
const store = configureStore({
  reducer: {
    reducer: reducer, 
    OpenNavBar: OpenNavBar,
    cabinetData: cabinetData,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger),
 
});

export default store;
