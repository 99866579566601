import React from "react";
import GraphicalStructureTitle from "./GraphicalStructureTitle";
import InviteCounts from "./InviteCounts";
import GraphicalRepresentation from "./GraphicalRepresentation";

function GraphicalStructure() {
    return (
        <>
            <div className="wrapperPartners">
                <GraphicalStructureTitle/>
            </div>

            <GraphicalRepresentation/>
        </>
    )
}

export default GraphicalStructure