import React, { useEffect, useState } from "react";
import '../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function GraphicalStructureTitle () {
    const { t, i18n } = useTranslation();


    return(
        <div className="nameWrapper">
        <span className="nameText">

        {t('CabinetPartnerLinearStructureTitle')}

        </span>
        </div>
    )
}

export default GraphicalStructureTitle