import axios from 'axios';
import {ENDPOINTS} from "../configs/endpoints";
import urlFromTemplate from "../configs/url";

const fetchImageById = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
        const response = await axios.get(apiUrl, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true'
            },
            responseType: 'blob' // Ensure response is in blob format
        });

        // Convert blob to object URL
        return URL.createObjectURL(response.data);
    } catch (error) {
        console.error(`Failed to fetch image/document with id ${id}`, error);
        return null;
    }
};

export { fetchImageById };
