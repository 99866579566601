// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerMainPageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footerLinksWrapper {
  padding-top: 100px;
  padding-bottom: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.footerLegalInformation {
  display: flex;
  flex-direction: column;
}

.footerLegaInfoText {
  color: gray;
}

.policyFooterWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.policyFooterLinks {
  margin-top: 5px;
  text-decoration: none;
  color: #4287f5;
}

.policyFooterLinksWrapper {
  display: flex;
  align-items: center;
}

.footerCopyWrapper {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  color: grey;
  padding-bottom: 20px;
  margin: 0 auto;
}

.footerCopy {
  margin-right: 5px;
}

@media screen and (max-width: 850px) {
  .footerLinksWrapper {
    flex-direction: column;
  }
  #footerLegalInformation {
    margin-top: 20px;
  }
}
@media screen and (max-width: 550px) {
  .footerLegalInformation {
    width: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEA,aAAA;EAEA,sBAAA;EACA,6BAAA;AADJ;;AAIA;EACI,kBAAA;EACA,oBAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,UAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,eAAA;EACA,qBAAA;EACA,cAAA;AADJ;;AAIA;EACI,aAAA;EAEA,mBAAA;AAFJ;;AAKA;EAEI,aAAA;EACA,2BAAA;EACA,UAAA;EACA,WAAA;EACA,oBAAA;EACA,cAAA;AAHJ;;AAMA;EACI,iBAAA;AAHJ;;AAMA;EACI;IACI,sBAAA;EAHN;EAME;IACI,gBAAA;EAJN;AACF;AAQA;EAGI;IACI,UAAA;EARN;AACF","sourcesContent":[".footerMainPageWrapper{\n    width: 100%;\n    // height: 200px;\n    display: flex;\n    // align-items: center;\n    flex-direction: column;\n    justify-content: space-around;\n}\n\n.footerLinksWrapper{\n    padding-top: 100px;\n    padding-bottom: 50px;\n    margin: 0 auto;\n    display: flex;\n    justify-content: space-between;\n    width: 90%;\n}\n\n.footerLegalInformation{\n    display: flex;\n    flex-direction: column;\n}\n\n.footerLegaInfoText{\n    color: gray;\n}\n\n.policyFooterWrapper{\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n}\n\n.policyFooterLinks{\n    margin-top: 5px;\n    text-decoration: none;\n    color: #4287f5;\n}\n\n.policyFooterLinksWrapper{\n    display: flex;\n    // justify-content: center;\n    align-items: center;\n}\n\n.footerCopyWrapper{\n    // margin-left: 50px ;\n    display: flex;\n    justify-content: flex-start;\n    width: 90%;\n    color: grey;\n    padding-bottom: 20px ;\n    margin: 0 auto;\n}\n\n.footerCopy{\n    margin-right: 5px;\n}\n\n@media screen and (max-width: 850px) {\n    .footerLinksWrapper{\n        flex-direction: column;\n    }\n\n    #footerLegalInformation{\n        margin-top: 20px;\n    }\n}\n\n\n@media screen and (max-width: 550px) {\n\n\n    .footerLegalInformation{\n        width: 80%;\n        // margin: 0 auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
