import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dataCab: {

    }
}

const cabSlice = createSlice({
    name: 'cabinetData',
    initialState,
    reducers:{
        setCabinet: (state, action) =>{
            state.dataCab = action.payload
        }
    }
})

export const {setCabinet} = cabSlice.actions
export default cabSlice.reducer